import { useState, useContext, useEffect } from 'react';
import logo from './assets/logo_256_rounded.webp';
import menuIcon from './assets/652ed471db2d5fa59b254763_Menu Icon.svg';
import coin from '../assets/icons/Loot_06.webp';
import gem from '../assets/icons/Quest_104.webp';
import { LoginContext } from './LoginProvider';
import { GlobalContext } from './GlobalProvider';
import { isLoggedIn, resetSession } from '../lib/session';
import { BrowserView, MobileView } from 'react-device-detect';

const Navigation = () => {
    const { showLoginModal } = useContext(LoginContext);
    const { coinsBalance, gemsBalance } = useContext(GlobalContext);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const getSelectedStyle = (pathname) => {
        if (pathname.replaceAll('/', '') === window.location.pathname.replaceAll('/', '')) {
            return { color: 'black' };
        }
        return {};
    };

    const onLoginLogoutPress = () => {
        if (!isLoggedIn()) {
            showLoginModal();
            return;
        }
        resetSession();
        window.location.href = '/';
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div data-collapse="small" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className={`nav-bar w-nav ${isMenuOpen ? 'nav-open' : ''}`}>
            <div className="nav-container w-container">
                {isLoggedIn() ? (
                    <div style={{ backgroundColor: 'white', padding: 6, paddingLeft: 16, paddingRight: 16, borderRadius: 36, height: 36, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <img src={coin} style={{ width: 26, height: 26 }} alt="Coin Icon"></img>
                        <p style={{ fontSize: 18, fontWeight: 'bold', marginLeft: 4 }}>{'Coins: '}</p>
                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#E85E2E', marginLeft: 4 }}>{coinsBalance}</p>
                        <img src={gem} style={{ width: 26, height: 26, marginLeft: 12 }} alt="Gem Icon"></img>
                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#02a2f7', marginLeft: 4 }}>{gemsBalance}</p>
                    </div>
                ) : (
                    <div className="logo-div">
                        <a href="/" className="nav-logo w-inline-block w--current">
                            <img src={logo} width="48" sizes="(max-width: 479px) 67vw, 178px" alt="Lootsource Logo" className="logo" />
                        </a>
                    </div>
                )}
                <nav role="navigation" className="nav-content w-nav-menu" data-nav-menu-open={isMenuOpen ? true : undefined}>
                    <div className="nav-menu">
                        <a href="/rewards/" className="nav-link w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/rewards/') }}>
                            Rewards
                        </a>
                    </div>
                    {isLoggedIn() ? (
                        <div className="nav-menu">
                            <a href="/my-progress/" className="nav-link w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/my-progress/') }}>
                                My Progress
                            </a>
                        </div>
                    ) : null}
                    <div className="nav-menu">
                        <a href="/games/" className="nav-link w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/games/') }}>
                            Games
                        </a>
                    </div>
                    {!isLoggedIn() ? (
                        <div className="nav-menu">
                            <a href="/surveys/" className="nav-link w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/surveys/') }}>
                                Surveys
                            </a>
                        </div>
                    ) : null}
                    {!isLoggedIn() ? (
                        <div className="nav-menu">
                            <a href="/cashback/" className="nav-link w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/cashback/') }}>
                                Cashback
                            </a>
                        </div>
                    ) : null}
                    <div className="nav-menu">
                        <a href="/contact/" className="nav-link w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/contact/') }}>
                            Contact
                        </a>
                    </div>
                    <MobileView>
                        <div className="nav-cta-button-container">
                            <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px' }}>
                                Get on Android
                            </a>
                        </div>
                    </MobileView>
                    <BrowserView>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {isLoggedIn() ? (
                                <div className="nav-cta-button-container">
                                    <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" className="nav-link cta-button-2 w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/download/') }}>
                                        Get on Android
                                    </a>
                                </div>
                            ) : (
                                <div className="nav-cta-button-container">
                                    <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" className="nav-link cta-button-2 w-nav-link" style={{ maxWidth: '1230px', ...getSelectedStyle('/download/') }}>
                                        Download
                                    </a>
                                </div>
                            )}
                            <div className="nav-cta-button-container" onClick={() => onLoginLogoutPress()}>
                                {isLoggedIn() ? (
                                    <a href="#" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px' }}>
                                        Log out
                                    </a>
                                ) : (
                                    <a href="#" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px' }}>
                                        Log in
                                    </a>
                                )}
                            </div>
                        </div>
                    </BrowserView>
                </nav>
                <div className="menu-button w-nav-button" style={{ WebkitUserSelect: 'text' }} aria-label="menu" role="button" tabIndex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded={isMenuOpen} onClick={toggleMenu}>
                    <img src={menuIcon} loading="lazy" width="24" className="menu-icon" alt="Menu Icon" />
                </div>
            </div>
            <div className={`w-nav-overlay ${isMenuOpen ? 'open' : ''}`} data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
    );
};

export default Navigation;
