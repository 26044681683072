import React from 'react';

import logo from './assets/logo_256_rounded.webp';

const Footer = () => {
    return (
        <footer style={styles.footer}>
            <div className="footer-container-2">
                <div style={styles.logoContainer}>
                    <img src={logo} alt="Lootsource Logo" style={styles.logo} />
                    <div style={styles.getInTouch}>
                        <p style={{ fontWeight: 'bold' }}>We are available at</p>
                        <a href='mailto:support@lootsource.app' style={{color: 'white'}}>support@lootsource.app</a>
                    </div>
                </div>
                <div style={styles.linksContainer}>
                    <div style={styles.column}>
                        <h4 style={{ color: 'white', fontWeight: 'bold' }}>Earn</h4>
                        <a href="/games/" style={styles.link}>Games</a>
                        <a href="/surveys/" style={styles.link}>Surveys</a>
                        <a href="/cashback/" style={styles.link}>Cashback</a>
                    </div>
                    <div style={styles.column}>
                        <h4 style={{ color: 'white', fontWeight: 'bold' }}>Spend</h4>
                        <a href="/rewards/" style={styles.link}>View Rewards</a>
                    </div>
                    <div style={styles.column}>
                        <h4 style={{ color: 'white', fontWeight: 'bold' }}>Website</h4>
                        <a href="/" style={styles.link}>Home</a>
                        <a href="/games/" style={styles.link}>Games</a>
                        <a href="/surveys/" style={styles.link}>Surveys</a>
                        <a href="/cashback/" style={styles.link}>Cashback</a>
                        <a href="/contact/" style={styles.link}>Contact</a>
                        <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" style={styles.link}>Download</a>
                        <a href="/terms-of-service/" style={styles.link}>Terms of Service</a>
                        <a href="/privacy-policy/" style={styles.link}>Privacy Policy</a>
                    </div>
                    <div style={styles.column}>
                        <h4 style={{ color: 'white', fontWeight: 'bold' }}>Also find us</h4>
                        <div style={styles.socialIcons}>
                            <a href="https://www.instagram.com/lootsource" style={styles.socialIcon}>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/150px-Instagram_logo_2022.svg.png" alt="Instagram Logo" style={styles.icon} />
                            </a>
                            <a href="https://www.trustpilot.com/review/lootsource.app" style={styles.socialIcon}>
                                <img src="https://static.wixstatic.com/media/cb18c4_aaa5cc27347d4d1f809e1194612f74f6~mv2.jpg" alt="Trustpilot Logo" style={styles.icon} />
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div style={styles.footerBottom}>
                <a href="/privacy-policy" style={styles.bottomLink}>Privacy Policy</a>
                <a href="/terms-of-service" style={styles.bottomLink}>Terms Of Service</a>
                <p style={{marginTop: 18}}>2024 Lootsource</p>
            </div>
        </footer>
    );
};

const styles = {
    footer: {
        backgroundColor: '#D76429',
        color: 'white',
        padding: '40px 20px',
        paddingTop: '60px',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    logoContainer: {
        flexBasis: '20%',
        marginBottom: '20px',
    },
    logo: {
        width: '150px',
        marginBottom: '20px',
    },
    getInTouch: {
        marginTop: '20px',
    },
    linksContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexBasis: '60%',
        flexWrap: 'wrap',
    },
    column: {
        marginBottom: '20px',
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        display: 'block',
        marginBottom: '8px',
    },
    socialMediaContainer: {
        flexBasis: '20%',
        textAlign: 'center',
    },
    socialIcons: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    socialIcon: {
        marginRight: 10,
        borderRadius: 12,
    },
    icon: {
        width: '30px',
        borderRadius: 12,
    },
    footerBottom: {
        borderTop: '1px solid white',
        paddingTop: '20px',
        textAlign: 'center',
        marginTop: '40px',
    },
    bottomLink: {
        color: 'white',
        textDecoration: 'none',
        margin: '0 10px',
    },
};

export default Footer;
