import React, { useEffect, useState, useRef, useContext } from "react";
import axiosClient from "../../lib/ax"; // Assuming axiosClient is correctly configured
import { PacmanLoader } from "react-spinners";

import { getSession, getUser, isLoggedIn } from "../../lib/session";
import { decode } from "html-entities";
import SelectedGame from "../../components/SelectedGame";
import QueryModal from "../../components/QueryModal";
import { GlobalContext } from "../../components/GlobalProvider";
import { Helmet } from "react-helmet";

const MyProgress = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [userGames, setUserGames] = useState([]);
    const [menuSelected, setMenuSelected] = useState('Trending');
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const [selectedGameError, setSelectedGameError] = useState(null);

    const initialLoaded = useRef(false);
    const containerRef = useRef(null);

    const { setCoinsBalance, setGemsBalance } = useContext(GlobalContext);

    useEffect(() => {

        if (!isLoggedIn()) {
            console.error('invalid SESSION');
            return;
        }
        getUserGames();
        refreshUser();
    }, []);

    const refreshUser = async () => {
        const user = await getUser();

        setCoinsBalance(user.coins_balance);
        localStorage.setItem('coinsBalance', user.coins_balance);

        setGemsBalance(user.gems_balance);
        localStorage.setItem('gemsBalance', user.gems_balance);
    }

    const getUserGames = async () => {
        try {
            const response = await axiosClient.get('/getuseroffer', {
                params: {
                    session: getSession(),
                }
            });

            setUserGames(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setInitialLoading(false);
            initialLoaded.current = true;
        }
    }

    const getProgressPercentage = (state) => {
        switch (state) {
            case 'inprogress':
                return '30%';
            case 'pending':
                return '70%';
            case 'approved':
                return '100%';
            case 'canceled':
                return '0%';
        }
    }

    return (
        <div className="section light-grey" style={{ marginTop: -72 }}>

            <Helmet>
                <title>My Progress</title>
                <meta name="description" content="Track progress of all the game missions your started. Know when you will receive your reward." />
                <meta name="keywords" content="track, progress, games, missions, reward" />
                <link rel="canonical" href="https://www.lootsource.app/my-progress/" />
            </Helmet>

            {selectedOfferId ? (
                <SelectedGame selectedOfferId={selectedOfferId} onClose={() => setSelectedOfferId(null)} onError={(message) => setSelectedGameError(message)} />
            ) : null}

            {selectedGameError ? (
                <QueryModal title={'Oops!'} message={selectedGameError} onClose={() => setSelectedGameError(null)} />
            ) : null}

            <div style={{ maxWidth: 1200, margin: '0 auto', marginTop: 100, overflowY: 'auto' }} ref={containerRef}>
                {initialLoading ? (
                    <div style={{ display: 'flex', padding: 32, justifyContent: 'center', height: 900 }}>
                        <PacmanLoader color="#D76429"></PacmanLoader>
                    </div>
                ) : (
                    <>
                        <h1 style={{ fontSize: 42, marginBottom: 64, color: '#495158', fontWeight: 700 }}>Track your progress</h1>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            {userGames.map((game, index) => (
                                <div key={index} style={{ backgroundColor: 'white', borderRadius: 32, padding: 26, width: 292, marginLeft: 4, marginRight: 4, marginBottom: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="cursorPointer" onClick={() => setSelectedOfferId(game.offer_id)}>
                                    <img src={game.offer_icon} style={{ width: 82, height: 82, margin: '0 auto', borderRadius: 18 }} alt={game.offer_title + ' progress'}></img>
                                    <p style={{ fontWeight: 'bold', color: 'black', marginTop: 18, fontSize: 18 }}>{decode(game.offer_title)}</p>

                                    {game.state === 'inprogress' ? (
                                        <p style={{ fontSize: 18, alignSelf: 'center', textAlign: 'center', marginTop: 2, color: '#8a8a8a', marginBottom: 6 }}>In Progress</p>
                                    ) : null}
                                    {game.state === 'pending' ? (
                                        <p style={{ fontSize: 18, alignSelf: 'center', textAlign: 'center', marginTop: 2, color: '#8a8a8a', marginBottom: 6 }}>In Review</p>
                                    ) : null}
                                    {game.state === 'approved' ? (
                                        <p style={{ fontSize: 18, alignSelf: 'center', textAlign: 'center', marginTop: 2, color: '#8a8a8a', marginBottom: 6 }}>Completed</p>
                                    ) : null}
                                    {game.state === 'canceled' ? (
                                        <p style={{ fontSize: 18, alignSelf: 'center', textAlign: 'center', marginTop: 2, color: '#8a8a8a', marginBottom: 6 }}>Canceled</p>
                                    ) : null}

                                    <div style={{
                                        backgroundColor: '#e3e3e3',
                                        width: '80%',
                                        height: 18,
                                        alignSelf: 'center',
                                        borderRadius: 8,
                                        marginTop: 6,
                                    }}>
                                        <div style={{
                                            height: '100%',
                                            width: getProgressPercentage(game.state),
                                            backgroundColor: '#FAA307',
                                            borderRadius: 12,
                                        }}>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div >
    )
}

export default MyProgress;
