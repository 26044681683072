import './App.css';
import Landing from './views/landing/Landing';
import Footer from './components/Footer';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Navigation from './components/Navigation';
import PrivacyPolicy from './views/privacy-policy/PrivacyPolicy';
import TermsOfService from './views/terms-of-service/TermsOfService';
import Contact from './views/contact/Contact';
import Games from './views/games/Games';
import { LoginProvider } from './components/LoginProvider';
import LoginModal from './components/LoginModal';
import { useRef } from 'react';
import Firebase from './lib/firebase';
import MyProgress from './views/progress/MyProgress';
import Modal from 'react-modal';
import Surveys from './views/surveys/Surveys';
import Cashback from './views/cashback/Cashback';
import Rewards from './views/rewards/Rewards';
import { GlobalProvider } from './components/GlobalProvider';
import Controller from './components/Controller';
import CookieConsent from './components/CookieConsent';

Modal.setAppElement('#root');

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "terms-of-service",
    element: <TermsOfService />
  },
  {
    path: "contact",
    element: <Contact />
  },
  {
    path: "games",
    element: <Games />
  },
  {
    path: "surveys",
    element: <Surveys />
  },
  {
    path: "cashback",
    element: <Cashback />
  },
  {
    path: "my-progress",
    element: <MyProgress />
  },
  {
    path: "rewards",
    element: <Rewards />
  },
]);

function App() {

  const isFirebaseInitialized = useRef(false);

  if (!isFirebaseInitialized.current) {
    isFirebaseInitialized.current = true;
    new Firebase();
  }

  return (
    <div className="App">
      <GlobalProvider>
        <LoginProvider>
          <Controller>
            <CookieConsent />
            <LoginModal />
            <Navigation />
            <RouterProvider router={router} />
            <Footer />
          </Controller>
        </LoginProvider>
      </GlobalProvider>
    </div>
  );
}

export default App;
