import React, { useState } from 'react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem('acceptedCookies', true);
  };

  if (!isVisible) return null;

  const acceptedCookies = localStorage.getItem('acceptedCookies');

  if (acceptedCookies) {
    return;
  }

  return (
    <div className="cookie-consent-container">
      This website uses cookies that are essential for proper functioning of the site.
      <button className="cookie-consent-button" onClick={handleAccept}>Acknowledge</button>
    </div>
  );
};

export default CookieConsent;