const config = {
    demoSession: 'NO_SESSION_ANON_SESSION',
    firebase: {
        apiKey: "AIzaSyDoqNeA4LUg-If9YvZ6KedPI495XJ-sEWY",
        authDomain: "cashfox-8bc3d.firebaseapp.com",
        projectId: "cashfox-8bc3d",
        appId: "1:1028231905898:android:f9efca7f9a99075b4a0013",
    }   
}

export default config;