import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => (
    <div className="section light-grey" style={{ marginTop: -72 }}>

        <Helmet>
            <title>Privacy Policy</title>
            <meta name="description" content="Get to know Lootsource's privacy policy." />
            <meta name="keywords" content="privacy, policy, privacy policy" />
            <link rel="canonical" href="https://www.lootsource.app/privacy-policy/" />
        </Helmet>

        <div style={{ backgroundImage: 'linear-gradient(120deg, #e85d04, #faa307)', width: '100%', height: 300, marginTop: -80 }} />
        <div className="container w-container" style={{ opacity: 1 }}>
            <div className="terms-card">
                <div className="text-box centered">
                    <h1 className="no-margin">Privacy Policy</h1>
                    <div className="spacer _16"></div>
                    <p className="paragraph" style={{ textAlign: 'center' }}>Last updated April 2024</p>
                    <div className="spacer _48"></div>
                    <h3>Introduction</h3>
                    <b>Lootsource values your data and understands the importance of your privacy. When you use our app, you trust us with your information. We handle your data safely and responsibly.</b>
                    <div className="spacer _16"></div>
                    <p>This Privacy Policy is meant to inform you about the data we collect, how we use the collected data, where the data is stored, why certain datum is collected, and how you can manage the data that we collected.</p>
                    <p>We highly advise you to carefully read this policy. This will help you understand how to comfortably use Lootsource in any way you like.</p>
                    <p>Downloading and using Lootsource means you read this Privacy Policy, understand it, and agree to it.</p>
                    <div className="spacer _48"></div>
                    <h3>Data we collect</h3>
                    <p>Data that we collect is divided into required and optional data. Required data is data that Lootsource needs in order to function. Since Lootsource does not work without collecting this information, you are unable to use Lootsource without providing required data.</p>
                    <p>Optional data is data that Lootsource might collect. It is not required for the application to function. You will always be notified whenever we try to collect your optional information. We will never collect this data without your consent.</p>
                    <div className="spacer _16"></div>
                    <b>Required data we collect:</b>
                    <div className="spacer _16"></div>
                    <ul>
                        <li>Advertising identifier (GAID)</li>
                        <li>E-mail</li>
                        <li>Password, if logged in with e-mail/password</li>
                        <li>Username</li>
                        <li>Device information (IMEI, device ID)</li>
                        <li>Network information (IP, carrier, connection type)</li>
                        <li>Non-precise location data (country)</li>
                        <li>Usage activity, including: games and surveys you clicked on, scanned cashback receipts, users you invited, used promotional codes and other similar usage activity data.</li>
                        <li>Other usage and diagnostic information</li>
                    </ul>
                    <div className="spacer _16"></div>
                    <b>Optional data we might collect from you:</b>
                    <div className="spacer _16"></div>
                    <ul>
                        <li>First and last name</li>
                        <li>Phone number</li>
                        <li>Precise location (ZIP code)</li>
                        <li>Gender, including sexual orientation & interests</li>
                        <li>Financial information (income, properties, shareholding information)</li>
                        <li>Health information (medications, diseases, insurance)</li>
                        <li>Insurance information</li>
                        <li>Date of birth</li>
                        <li>Social information</li>
                        <li>Political data and orientation</li>
                        <li>Family information without disclosing private data about family members</li>
                        <li>Information about personal digital interests (technology, movies, music, etc...)</li>
                        <li>Other personal information you might be asked to provide</li>
                    </ul>
                    <div className="spacer _48"></div>
                    <h3>Why we collect data</h3>
                    <p><b>Required data</b> is collected to assure proper functioning of the app. This allows you to log in, get game offers and surveys catered to your interests, and connects you to thousands of other players with similar interests.</p>
                    <p>It also helps us make Lootsource safer and more stable. This is because optional data is sufficient enough to rule out malicious users. All while being minimally intrusive.</p>
                    <p><b>Optional data</b> is not automatically collected. As you know, Lootsource is an app designated for earning money. One of the ways users can earn is by completing Surveys.</p>
                    <p>This is the <b>only</b> was Lootsource can collect optional data from you. We will <b>never</b> collect this data without your knowledge. When you choose to participate in a survey, you are asked to provide optional information manually. This information might not be on the list provided above.</p>
                    <p>The data is sent to us as soon as you answer the survey question. Participating in a survey, reading the question, and answering means you read this optional data usage paragraph, understand it, and agree with it.</p>
                    <p>Both <b>required</b> and <b>optional</b> data is stored on our servers. It could be processed multiple times for various reasons. Processing involves structuring data of users with similar interests or information, mostly for marketing reasons.</p>
                    <p>Collected data might be shared with third parties. We are partnered with third party companies for marketing reasons. Your information could be shared with one or more third party companies we are partnered with. The list of partnered companies will be referenced in the further text.</p>
                    <p>We will sometimes compress your data or anonymize it so that it cannot be connected with you. Other times, this will not be possible, and some data might be traceable back to you.</p>
                    <p>You always have the right for deletion of your data. If you wish to permanently remove any of your information from our servers, or wish to download your data that we collected, contact us via our official e-mail: support@lootsource.app.</p>
                    <div className="spacer _48"></div>
                    <h3>Companies we are partnered with</h3>
                    <ul>
                        <li>AdGateMedia</li>
                        <li>BitLabs</li>
                        <li>Aye-T Studios</li>
                        <li>AdScend Media</li>
                        <li>Pollfish</li>
                        <li>CPX Research</li>
                        <li>AdGem</li>
                        <li>Meta</li>
                        <li>Google</li>
                    </ul>
                    <div className="spacer _16"></div>
                    <p>The companies mentioned above have their own privacy policies. We highly advise you to carefully read these policies in order to be informed on how your shared data is handled and protected.</p>
                    <div className="spacer _48"></div>
                    <h3>Your rights</h3>
                    <b>Regarding your data, you always have a right to:</b>
                    <div className="spacer _16"></div>
                    <ul>
                        <li>Access your data and correct it</li>
                        <li>Remove your data</li>
                        <li>Download your data</li>
                        <li>File a complaint</li>
                    </ul>
                    <p>The interface for the actions mentioned above is still in development. Until then, for any inquiries or actions, contact us via our official e-mail address: support@lootsource.app.</p>
                    <div className="spacer _48"></div>
                    <h3>How long we keep your data</h3>
                    <p>Your data is kept on our servers and shared with our marketing partners for an indefinite amount of time. If you wish to permanently delete your data from our servers, and stop us from sharing it with our marketing partners, feel free to contact us via our official e-mail address: support@lootsource.app.</p>
                    <div className="spacer _48"></div>
                    <h3>Conclusion</h3>
                    <p>Lootsource collects your data. We collect required and optional data. We will never collect optional data without your permission. If you download Lootsource and use it, this means you agree with this Privacy Policy. This includes us collecting your data, storing it, processing it, and sharing it with third party companies. You can always remove or change your data by contacting us via our official e-mail address: support@lootsource.app.</p>
                </div>
            </div>
        </div>
    </div>
);

export default PrivacyPolicy;