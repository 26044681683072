import React, { createContext, useState } from 'react';

export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);

  const showLoginModal = () => setLoginModalVisible(true);
  const hideLoginModal = () => setLoginModalVisible(false);

  return (
    <LoginContext.Provider value={{ isLoginModalVisible, showLoginModal, hideLoginModal }}>
      {children}
    </LoginContext.Provider>
  );
};