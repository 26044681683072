import React, { useContext, useEffect, useState } from 'react';
import landingLarge1049 from './assets/landing_large_1049.webp';
import landingLarge500 from './assets/landing_large_500.webp';
import landingLarge800 from './assets/landing_large_800.webp';
import usersSolid from './assets/users-solid.webp';
import moneyBill from './assets/money-bill-transfer-solid.webp';
import usersRays from './assets/users-rays-solid.webp';
import cashbackPromo from './assets/cashback_web_promo.webp';
import offersPhoneDown from './assets/offers phone down.webp';
import surveysPromo from './assets/surveys web promo.webp';
import playStoreBadge from './assets/653182c9697ae17fa4f60fb5_download_playstore.webp';
import game1Promo from './assets/game1.webp';
import game2Promo from './assets/game2.webp';
import game3Promo from './assets/game3.webp';
import game4Promo from './assets/game4.webp';
import superMario from './assets/super_mario_running.webp';

import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faSquareCaretDown, fas } from '@fortawesome/free-solid-svg-icons';
import LandingShowcase from '../../components/LandingShowcase';

import Modal from 'react-modal';

import axiosClient from '../../lib/ax';
import { useLocation } from 'react-router-dom';
import { getSession, getUser } from '../../lib/session';
import { GlobalContext } from '../../components/GlobalProvider';
import { Helmet } from 'react-helmet';
import { BrowserView, MobileView } from 'react-device-detect';

const Landing = () => {

    const { setCoinsBalance, setGemsBalance } = useContext(GlobalContext);

    const [qna1Opened, setQna1Opened] = useState(false);
    const [qna2Opened, setQna2Opened] = useState(false);
    const [qna3Opened, setQna3Opened] = useState(false);
    const [qna4Opened, setQna4Opened] = useState(false);
    const [qna5Opened, setQna5Opened] = useState(false);
    const [showAllPlayers, setShowAllPlayers] = useState(false);

    const [players, setPlayers] = useState([]);
    const [playersOnline, setPlayersOnline] = useState('...');

    useEffect(() => {
        fetchLocation();
        fetchPlayers();
        fetchAutoConfig();
        refreshUser();
    }, []);

    const refreshUser = async () => {
        const user = await getUser();

        setCoinsBalance(user.coins_balance);
        localStorage.setItem('coinsBalance', user.coins_balance);

        setGemsBalance(user.gems_balance);
        localStorage.setItem('gemsBalance', user.gems_balance);
    }

    const fetchLocation = async () => {
        try {
            const response = await axiosClient.get('https://api.country.is', {
                params: {}
            });
            localStorage.setItem('countryCode', response.country);
            localStorage.setItem('ip', response.ip);
        } catch (error) {
            console.error(error);
        }
    }

    const fetchPlayers = async () => {
        try {
            const response = await axiosClient('/getplayers', {
                params: {
                    session: getSession(),
                }
            });
            setPlayers(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const fetchAutoConfig = async () => {
        try {
            const response = await axiosClient('/getautoconfig', {
                params: {
                    session: 'aab02d3c3fdb2b452adf62df3e8af74021771d2fd8750b30a73dc0f00ed8b1af',
                }
            });
            setPlayersOnline(`${response.data[0].users_online} users online`);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="page-wrapper">

            <Helmet>
                <title>Lootsource - Earn Money with Games, Surveys & Cashback Rewards</title>
                <meta name="description" content="Redesigned and fun way of earning money online, now also on web! Complete Game missions, answer surveys, and receive store cashbacks, all with one app." />
                <meta name="keywords" content="loot, source, lootsource, earn, money, online" />
                <link rel="canonical" href="https://www.lootsource.app/" />
            </Helmet>

            <div className="section light-color-gradient" style={{ marginTop: -72 }}>
                <div className="container" style={{ opacity: 1, marginTop: 72 }}>
                    <div>
                        <h1 className="heading-2"><strong className="bold-text">Lootsource</strong></h1>
                        <p style={{ fontSize: 22, color: 'white' }}>Make money online. Fun, gamified, redesigned.</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px', marginBottom: '12px' }}>
                        <div style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', padding: '8px', borderRadius: '24px', paddingLeft: '14px', paddingRight: '14px' }}>
                            <div style={{ width: 18, height: 18, borderRadius: 9, backgroundColor: 'green', marginRight: '8px' }}></div>
                            <p style={{ margin: 0 }}>{playersOnline}</p>
                        </div>
                    </div>
                    <div className="div-block">
                        <img
                            src={landingLarge1049}
                            srcSet={`${landingLarge1049} 1049w ${landingLarge800} 800w ${landingLarge500} 500w`}
                            className="image"
                            loading="lazy"
                            width="939"
                            sizes="(max-width: 767px) 92vw, (max-width: 991px) 94vw, (max-width: 1279px) 90vw, 939px"
                            alt="Landing Image"
                        />
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="showcase-container-wrapper">
                    <div className="showcase-features">
                        <LandingShowcase title="100,000+" info="Registered users" className="showcase-feature-item feature-item-1" image={usersSolid} overlayText={"Join the community of over 100,000 players registered via our app or website."} />
                        <LandingShowcase title="$50,000" info="Paid out to users" className="showcase-feature-item feature-item-2" image={moneyBill} overlayText={"In only a few months of existence, we already paid our users over $50k!"} />
                        <LandingShowcase title="5,000+" info="Daily active users" className="showcase-feature-item feature-item-3" image={usersRays} overlayText={"Over 5,000 people visit Lootsource at least once a day, every day."} />
                    </div>
                </div>

                <div className="showcase-container-wrapper">
                    <div className="cashback-container">
                        <div className="cashback-container-left" style={{ flex: 3 }}>
                            <img src={cashbackPromo} width="650" alt="Store cashback promotional image" style={{ justifySelf: 'flex-end' }} />
                        </div>
                        <div className="cashback-container-right" style={{ flex: 2 }}>
                            <h1 className="cashback-heading">Earn Cashbacks</h1>
                            <p className="cashback-info-text">Shop your favorite items and receive a cashback for them! Check our store offers to find out more and items that are on cashback!</p>
                            <div className="nav-cta-button-container" style={{ marginTop: '40px' }}>
                                <a href="/cashback/" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '24px', fontSize: 16, fontWeight: 'bold' }}>Get your Cashback!</a>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="artifacts-section" style={{ marginBottom: 80 }}>
                    <div className="artifacts-containeir">
                        <div className="artifacts-containeir-left">
                            <div className="artifacts-title-text">Test Video Games</div>
                            <p className="artifacts-info-text">Love playing games? If so, you can now earn money playing them! Choose from hundreds of gaming missions, complete them, and receive rewards right away. You can filter games by difficulty, reward, and even likes!</p>
                            <div className="artifacts-games-promo">
                                <img src={game1Promo} className="game-promo-image" alt="First video game promotional image" />
                                <img src={game2Promo} className="game-promo-image" alt="Second video game promotional image" />
                                <img src={game3Promo} className="game-promo-image" alt="Thrid video game promotional image" />
                                <img src={game4Promo} className="game-promo-image" alt="Fourth video game promotional image" />
                            </div>
                            <div className="nav-cta-button-container" style={{ marginTop: '40px' }}>
                                <a href="/games/" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '24px', backgroundColor: 'white', color: '#333', fontSize: 16, fontWeight: 'bold' }}>Start playing now!</a>
                            </div>
                        </div>
                        <div className="artifacts-containeir-right">
                            <img src={offersPhoneDown} alt="Game offers promotional image" />
                        </div>
                    </div>
                </section>

                <div className="showcase-container-wrapper">
                    <div className="cashback-container">
                        <div className="cashback-container-left surveys-container-left" style={{ flex: 4 }}>
                            <img src={surveysPromo} alt="Surveys promotional image" />
                        </div>
                        <div className="cashback-container-right surveys-container-right" style={{ flex: 4 }}>
                            <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Answers for Cash</h1>
                            <p className="cashback-info-text" style={{ textAlign: 'left' }}>How would you like to earn money for your opinion? That's right! We pay you to answer simple questions! We have partnered with over 50 different companies to give you access to hundreds of surveys! You can sort them by category, payout, length, and more! Simply click the link below to find out more.</p>
                            <div className="nav-cta-button-container" style={{ marginTop: '40px' }}>
                                <a href="./surveys/" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '24px', backgroundColor: '#e85d04', color: 'white', fontSize: 16, fontWeight: 'bold' }}>Start surveys now!</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="showcase-container-wrapper">
                    <div className="surveys-container" style={{ padding: 40, paddingTop: 80 }}>
                        <h1 className="track-payouts-heading">Track your payout</h1>
                        <p className="track-payouts-info">Waiting for your reward can be frustrating. This is why Lootsource allows you to track your withdrawal at any time.</p>
                        <div style={{ marginTop: 60, padding: 0 }}>
                            <img src={superMario} style={{ width: 100, height: 100, marginLeft: '60%', marginBottom: 10 }} alt="Super Mario running"></img>
                            <div className="track-payouts-bar-out">
                                <div style={{ width: '71%', backgroundColor: '#EE7B2D', height: 20, borderRadius: 12 }}></div>
                            </div>
                            <p style={{ textAlign: 'center', fontSize: 18, marginTop: 24, color: '#EE7B2D' }}>You will receive your video game in 48 hours!</p>
                        </div>
                    </div>
                </div>

                <div className="showcase-container-wrapper">
                    <div className="faq-container">
                        <div className="faq-container-left">
                            <h1 className="cashback-heading" style={{ textAlign: 'left', color: 'white' }}>
                                Frequently Asked Questions
                            </h1>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: 'white' }}>
                                Note that you can always contact us if you have any other inquiries.
                            </p>
                        </div>
                        <div className="faq-container-right">
                            <div style={{ borderBottom: 'solid', borderBottomColor: '#b0b0b0', borderBottomWidth: '2px', marginBottom: '44px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="cursorPointer" onClick={() => setQna1Opened(!qna1Opened)}>
                                    <h3 className="cashback-heading" style={{ textAlign: 'left', color: 'white' }}>
                                        How much can I earn?
                                    </h3>
                                    {qna1Opened ? (
                                        <FontAwesomeIcon icon={faArrowUp} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    ) : (
                                        <FontAwesomeIcon icon={faArrowDown} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    )}
                                </div>
                                <Collapse isOpened={qna1Opened}>
                                    <p className="cashback-info-text" style={{ textAlign: 'left', color: 'white' }}>
                                        Earnings highly depend on how much time you are ready to invest into Lootsource. An average active user earns around $12 per day. Join our platform and explore your earning opportunities!
                                    </p>
                                </Collapse>
                            </div>
                            <div style={{ borderBottom: 'solid', borderBottomColor: '#b0b0b0', borderBottomWidth: '2px', marginBottom: '44px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="cursorPointer" onClick={() => setQna4Opened(!qna4Opened)}>
                                    <h3 className="cashback-heading" style={{ textAlign: 'left', color: 'white' }}>
                                        What is the minimum payout amount?
                                    </h3>
                                    {qna4Opened ? (
                                        <FontAwesomeIcon icon={faArrowUp} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    ) : (
                                        <FontAwesomeIcon icon={faArrowDown} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    )}
                                </div>
                                <Collapse isOpened={qna4Opened}>
                                    <p className="cashback-info-text" style={{ textAlign: 'left', color: 'white' }}>
                                        Unlike other earning applications, the minimum payout amount in Lootsource is only $1. Almost half of our active users withdraws this amount in the same day of joining Lootsource.
                                    </p>
                                </Collapse>
                            </div>
                            <div style={{ borderBottom: 'solid', borderBottomColor: '#b0b0b0', borderBottomWidth: '2px', marginBottom: '44px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="cursorPointer" onClick={() => setQna2Opened(!qna2Opened)}>
                                    <h3 className="cashback-heading" style={{ textAlign: 'left', color: 'white' }}>
                                        What are the payout methods?
                                    </h3>
                                    {qna2Opened ? (
                                        <FontAwesomeIcon icon={faArrowUp} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    ) : (
                                        <FontAwesomeIcon icon={faArrowDown} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    )}
                                </div>
                                <Collapse isOpened={qna2Opened}>
                                    <p className="cashback-info-text" style={{ textAlign: 'left', color: 'white' }}>
                                        You can exchange your earnings for Gift Cards, Video Games, or simply choose a PayPal payout! We are adding more payout methods as you are reading this.
                                    </p>
                                </Collapse>
                            </div>
                            <div style={{ borderBottom: 'solid', borderBottomColor: '#b0b0b0', borderBottomWidth: '2px', marginBottom: '44px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="cursorPointer" onClick={() => setQna3Opened(!qna3Opened)}>
                                    <h3 className="cashback-heading" style={{ textAlign: 'left', color: 'white' }}>
                                        How fast are payouts processed?
                                    </h3>
                                    {qna3Opened ? (
                                        <FontAwesomeIcon icon={faArrowUp} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    ) : (
                                        <FontAwesomeIcon icon={faArrowDown} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    )}
                                </div>
                                <Collapse isOpened={qna3Opened}>
                                    <p className="cashback-info-text" style={{ textAlign: 'left', color: 'white' }}>
                                        90% of our payouts are delivered within 24 hours of the payout request. This can sometimes differ based on the payout amount.
                                    </p>
                                </Collapse>
                            </div>
                            <div style={{ borderBottom: 'solid', borderBottomColor: '#b0b0b0', borderBottomWidth: '2px', marginBottom: '44px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="cursorPointer" onClick={() => setQna5Opened(!qna5Opened)}>
                                    <h3 className="cashback-heading" style={{ textAlign: 'left', color: 'white' }}>
                                        Can I use Lootsource on web?
                                    </h3>
                                    {qna5Opened ? (
                                        <FontAwesomeIcon icon={faArrowUp} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    ) : (
                                        <FontAwesomeIcon icon={faArrowDown} color='white' style={{ fontSize: 32, marginRight: 18 }}></FontAwesomeIcon>
                                    )}
                                </div>
                                <Collapse isOpened={qna5Opened}>
                                    <p className="cashback-info-text" style={{ textAlign: 'left', color: 'white' }}>
                                        Yes! Our web alternative is an amazing option for users that don't have android devices. For now, Lootsource Web is limited to video games missions only, but surveys and store cashbacks will soon also be available.
                                    </p>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="showcase-container-wrapper">
                    <div className="winners-container">
                        <div className="winners-container-left" style={{ flex: 3 }}>
                            {players.slice(0, 5).map((player, index) => (
                                <div key={index} style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', backgroundColor: '#f5f5f5', padding: 12, borderRadius: 18 }}>
                                    <img src={player.icon} width={32} height={32} style={{ borderRadius: 12 }} alt="Player Icon" />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1, alignItems: 'center', marginLeft: '12px' }}>
                                        <p style={{ margin: 0 }}>{player.username}</p>
                                        <p style={{ margin: 0, fontWeight: 'bold' }}>${(player.payout / 500).toFixed(2)}</p>
                                    </div>
                                </div>
                            ))}

                            {!showAllPlayers ? (
                                <p style={{ textDecoration: 'underline', fontSize: 22, textAlign: 'center' }} className="cursorPointer" onClick={() => setShowAllPlayers(true)}>Show more</p>
                            ) : null}

                            <Collapse isOpened={showAllPlayers}>
                                {players.slice(5, 20).map((player, index) => (
                                    <div key={index} style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', backgroundColor: '#f5f5f5', padding: 12, borderRadius: 18 }}>
                                        <img src={player.icon} width={32} height={32} style={{ borderRadius: 12 }} alt="Player Icon" />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1, alignItems: 'center', marginLeft: '12px' }}>
                                            <p style={{ margin: 0 }}>{player.username}</p>
                                            <p style={{ margin: 0, fontWeight: 'bold' }}>${(player.payout / 500).toFixed(2)}</p>
                                        </div>
                                    </div>
                                ))}
                            </Collapse>
                        </div>
                        <div className="winners-container-right">
                            <h1 className="cashback-heading">Most Recent Winners</h1>
                            <p className="cashback-info-text">We proudly present the live list of our most recent winners. You can join this list within the first 30 minutes of using Lootsource!</p>
                        </div>
                    </div>
                </div>

                <BrowserView className="download-container" style={{ marginTop: 80 }}>
                    <div className="download-container-left">
                        <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" rel="noopener noreferrer">
                            <img src={playStoreBadge} alt="Google Play store download badge" />
                        </a>
                    </div>
                    <div className="download-container-right">
                        <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Get The App Now</h1>
                        <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Get started today and find out how much money you can earn with Lootsource. We're currently only available for Android and Web but will be expanding to iOS soon!</p>
                    </div>
                </BrowserView>

                <MobileView className="download-container" style={{ marginTop: 80 }}>
                    <div className="download-container-top">
                        <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Get The App Now</h1>
                        <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Get started today and find out how much money you can earn with Lootsource. We're currently only available for Android and Web but will be expanding to iOS soon!</p>
                    </div>
                    <div className="download-container-bottom">
                        <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" rel="noopener noreferrer">
                            <img src={playStoreBadge} alt="Google Play store download badge" />
                        </a>
                    </div>
                </MobileView>
            </div>
        </div>
    );
};

export default Landing;
