import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { getSession, getUser, isLoggedIn } from "../../lib/session";
import { PacmanLoader } from "react-spinners";
import axiosClient from "../../lib/ax";
import MiniOfferMenu from "../../components/MiniOfferMenu";
import { faBox, faCreditCard, faGamepad, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import playStoreBadge from '../landing/assets/653182c9697ae17fa4f60fb5_download_playstore.webp';

import coin from '../../assets/icons/Loot_06.webp';
import gem from '../../assets/icons/Quest_104.webp';
import QueryModal from "../../components/QueryModal";
import Modal from 'react-modal';
import PayPalPayout from "../../components/PayPalPayout";
import { GlobalContext } from "../../components/GlobalProvider";
import { timeAgo, timeUntil } from "../../lib/time";
import { Helmet } from "react-helmet";
import { BrowserView, MobileView } from "react-device-detect";

const Rewards = () => {

    const [initialLoading, setInitialLoading] = useState(true);
    const [rewardsLoading, setRewardsLoading] = useState(false);
    const [purchasesLoading, setPurchasesLoading] = useState(false);

    const [menuSelected, setMenuSelected] = useState('Payout');
    const [storeItems, setStoreItems] = useState([]);
    const [purchases, setPurchases] = useState([]);

    const [isBuying, setIsBuying] = useState(false);
    const [purchaseError, setPurchaseError] = useState(null);
    const [purchaseListError, setPurhcaseListError] = useState(null);
    const [rewardsListError, setRewardsListError] = useState(null);

    const [menuItems, setMenuItems] = useState([
        {
            title: 'Payout',
            faIcon: faMoneyBill,
            onPress: () => setMenuSelected('Payout'),
        },
        {
            title: 'Gift Cards',
            faIcon: faCreditCard,
            onPress: () => setMenuSelected('Gift Cards'),
        },
        {
            title: 'Games',
            faIcon: faGamepad,
            onPress: () => setMenuSelected('Games'),
        },
    ]);

    const { setCoinsBalance, coinsBalance, setGemsBalance } = useContext(GlobalContext);

    useEffect(() => {
        if (localStorage.getItem('coinsBalance')) {
            setCoinsBalance(localStorage.getItem('coinsBalance'));
        }

        if (localStorage.getItem('gemsBalance')) {
            setGemsBalance(localStorage.getItem('gemsBalance'));
        }

        if (isLoggedIn()) {
            const tempMenuItems = [...menuItems];
            tempMenuItems.push({
                title: 'Purchased',
                faIcon: faBox,
                onPress: () => setMenuSelected('Purchased'),
            });
            setMenuItems(tempMenuItems);
        }
    }, []);

    useEffect(() => {
        if (menuSelected !== 'Purchased') {
            setStoreItems([]);
            getRewards();
            refreshUser();
        } else {
            getPurhcases();
        }

    }, [menuSelected]);

    const refreshUser = async () => {
        const user = await getUser();

        setCoinsBalance(user.coins_balance);
        localStorage.setItem('coinsBalance', user.coins_balance);

        setGemsBalance(user.gems_balance);
        localStorage.setItem('gemsBalance', user.gems_balance);
    }

    const getRewards = async () => {
        setRewardsLoading(true);
        try {
            const response = await axiosClient.get('/getstoreitems', {
                params: {
                    session: getSession(),
                    type: menuSelected.toLowerCase(),
                    page: 0,
                }
            });

            const tempItems = [];

            for (const tempItem of response.data.items) {
                if (tempItem.platforms) {
                    tempItem.platforms = tempItem.platforms.split(';');
                    tempItem.selectedPlatform = tempItem.platforms[0];
                }
                if (tempItem.regions) {
                    tempItem.regions = tempItem.regions.split(';');
                    tempItem.selectedRegion = tempItem.regions[0];
                }
                tempItems.push(tempItem);
            }

            setStoreItems(tempItems);
        } catch (error) {
            if (error.message) {
                setRewardsListError(error.message);
            } else {
                setRewardsListError('An unexpected error occurred while trying to list store items. Please contact support.')
            }
        } finally {
            setInitialLoading(false);
            setRewardsLoading(false);
        }
    }

    const getPurhcases = async () => {
        setPurchasesLoading(true);
        try {
            const response = await axiosClient.get('getpurchasedstoreitems', {
                params: {
                    session: getSession(),
                }
            });

            setPurchases(response.data.items);
        } catch (error) {
            if (error.message) {
                setPurhcaseListError(error.message);
            } else {
                setPurhcaseListError('An unexpected error occurred while trying to list your purhcases. Please contact support.')
            }
        } finally {
            setPurchasesLoading(false);
        }
    }

    const purchaseItem = async (item, currency) => {
        try {
            setIsBuying(true);
            await axiosClient.get('purchasestoreitem', {
                params: {
                    session: getSession(),
                    storeId: item.store_id,
                    currency,
                    platform: item.selectedPlatform,
                    region: item.selectedRegion,
                }
            });
        } catch (error) {
            switch (error.message) {
                case 'insufficient_balance':
                    setPurchaseError(`You don't have enough ${currency} to purchase this item,`);
                    break;

                default:
            }
        } finally {
            setIsBuying(false);
        }
    }

    const selectItemPlatform = (item, platform) => {
        console.log(item, platform);
        const tempItems = [...storeItems];

        for (let i = 0; i < tempItems.length; i += 1) {
            if (tempItems[i].store_id === item.store_id) {

                tempItems[i].selectedPlatform = platform;
                console.log(tempItems[i])
            }
        }
        setStoreItems(tempItems);
    }

    const selectItemRegion = (item, region) => {
        const tempItems = [...storeItems];

        for (let i = 0; i < tempItems.length; i += 1) {
            if (tempItems[i].store_id === item.store_id) {
                tempItems[i].selectedRegion = region;
            }
        }
        setStoreItems(tempItems);
    }

    const loadingModalStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "600px",
            transform: "translate(-50%, -50%)",
            padding: "0px",
            maxHeight: "70%",
            backgroundColor: '#00000000',
            border: '0px solid #00000000'
        }
    }

    return (
        <div className="section light-grey" style={{ marginTop: -72 }}>

            <Helmet>
                <title>Rewards</title>
                <meta name="description" content="List possible rewards, issue paypal withdrawal and track status." />
                <meta name="keywords" content="rewards, track, status, paypal, gift cards" />
                <link rel="canonical" href="https://www.lootsource.app/rewards/" />
            </Helmet>

            {isBuying ? (
                <Modal
                    isOpen={true}
                    onRequestClose={() => console.log('ignoring close')}
                    contentLabel={'Selected Game Offer'}
                    style={loadingModalStyle}
                >

                    <div style={{ display: 'flex', padding: 32, justifyContent: 'center', height: 200 }}>
                        <PacmanLoader color="#D76429"></PacmanLoader>
                    </div>
                </Modal>
            ) : null}

            {purchaseError ? (
                <QueryModal title={'Oops!'} message={purchaseError} onClose={() => setPurchaseError(null)} />
            ) : null}

            {rewardsListError ? (
                <QueryModal title={'Oops!'} message={rewardsListError} onClose={() => setRewardsListError(null)} />
            ) : null}

            {purchaseListError ? (
                <QueryModal title={'Oops!'} message={purchaseListError} onClose={() => setPurhcaseListError(null)} />
            ) : null}

            <div style={{ maxWidth: 1200, margin: '0 auto', marginTop: 100, overflowY: 'auto' }}>
                {initialLoading ? (
                    <div style={{ display: 'flex', padding: 32, justifyContent: 'center', height: 900 }}>
                        <PacmanLoader color="#D76429"></PacmanLoader>
                    </div>
                ) : (
                    <>
                        <BrowserView>
                            <h1 style={{ fontSize: 42, marginBottom: 64, color: '#495158', fontWeight: 700 }}>Exchange coins for money</h1>
                            <MiniOfferMenu
                                selected={menuSelected}
                                menuItems={menuItems}
                            />
                            {menuSelected === 'Payout' ? (
                                <PayPalPayout coins={coinsBalance} />
                            ) : null}

                            {(menuSelected === 'Gift Cards' || menuSelected === 'Games') ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {rewardsLoading ? (
                                        <PacmanLoader color="#D76429" style={{ marginBottom: 900 }}></PacmanLoader>
                                    ) : null}
                                    {storeItems.map((item, index) => (
                                        <div style={{ minWidth: 392, marginLeft: 4, marginRight: 4, marginBottom: 8 }} key={index}>
                                            <div style={{ backgroundImage: `url(${item.image})`, backgroundPositionY: '50%', backgroundSize: 'cover', width: '100%', height: 100, borderTopLeftRadius: 36, borderTopRightRadius: 36, }} />
                                            <div style={{ borderBottomLeftRadius: 36, borderBottomRightRadius: 36, backgroundColor: 'white', padding: 20 }}>
                                                <p style={{ fontWeight: 'bold', fontSize: 18 }}>{item.name}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ flex: 1, borderRight: '1px solid #d1d1d1', paddingRight: 12 }}>
                                                        <img src={coin} style={{ width: 32, height: 32 }} alt={item.name + ' progress'} />
                                                        <p style={{ fontSize: 18, margin: 0, color: '#495158' }}> Coins price: </p>
                                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#FAA307', margin: 0 }}>{item.coins_price}</p>
                                                        {isLoggedIn() ? (
                                                            <p style={{ margin: 0, padding: 12, fontWeight: 'bold', fontSize: 18, color: '#E85E2E', borderRadius: 18, marginTop: 12 }} className="cursorPointer" onClick={() => purchaseItem(item, 'coins')}>Buy with Coins</p>
                                                        ) : null}
                                                    </div>
                                                    <div style={{ flex: 1, paddingLeft: 12, paddingRight: 12 }}>
                                                        <img src={gem} style={{ width: 32, height: 32 }} alt={item.name + ' progress'} />
                                                        <p style={{ fontSize: 18, margin: 0, color: '#495158' }}> Gems price: </p>
                                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#02a2f7', margin: 0 }}>{item.gems_price}</p>
                                                        {isLoggedIn() ? (
                                                            <p style={{ margin: 0, padding: 12, fontWeight: 'bold', fontSize: 18, color: '#02a2f7', borderRadius: 18, marginTop: 12 }} className="cursorPointer" onClick={() => purchaseItem(item, 'gems')}>Buy with Gems</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}

                            {(menuSelected === 'Purchased') ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {purchasesLoading ? (
                                        <PacmanLoader color="#D76429" style={{ marginBottom: 900 }}></PacmanLoader>
                                    ) : null}
                                    {purchases.map((item, index) => (
                                        <div style={{ minWidth: 392, marginLeft: 4, marginRight: 4, marginBottom: 8 }} key={index}>
                                            <div style={{ backgroundImage: `url(${item.image})`, backgroundPositionY: '50%', backgroundSize: 'cover', width: '100%', height: 100, borderTopLeftRadius: 36, borderTopRightRadius: 36, }} />
                                            <div style={{ borderBottomLeftRadius: 36, borderBottomRightRadius: 36, backgroundColor: 'white', padding: 20 }}>
                                                <p style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'left' }}>{item.name}</p>
                                                <p style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'left', color: '#e85d04', marginBottom: 6 }}>{item.status.toUpperCase()}</p>
                                                <p style={{ fontSize: 18, color: '#495158', textAlign: 'left', marginBottom: 6 }}>Purchased {timeAgo(null, item.purchase_timestamp)} ago</p>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p style={{ fontSize: 18, color: '#495158', textAlign: 'left' }}>Expected delivery: </p>
                                                    <p style={{ fontSize: 18, color: '#495158', textAlign: 'left', fontWeight: 'bold' }}>{timeUntil(item.delivery_timestamp)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </BrowserView>
                        <MobileView>
                            <h1 style={{ fontSize: 42, marginBottom: 64, color: '#495158', fontWeight: 700 }}>Exchange coins for money</h1>
                            <div className="download-container" style={{ marginTop: 80 }}>
                                <div className="download-container-top">
                                    <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Find all rewards in app!</h1>
                                    <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>To explore all amazing rewards that Lootsource has to offer, please download our official Android app.</p>
                                </div>
                                <div className="download-container-bottom">
                                    <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" rel="noopener noreferrer">
                                        <img src={playStoreBadge} alt="Google Play store download badge" />
                                    </a>
                                </div>
                            </div>
                        </MobileView>
                    </>
                )}
            </div>
        </div >
    )
}

export default Rewards;