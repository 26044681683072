import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../components/GlobalProvider';
import { Helmet } from 'react-helmet';

const Contact = () => {

    const { setCoinsBalance, setGemsBalance } = useContext(GlobalContext);

    useEffect(() => {
        if (localStorage.getItem('coinsBalance')) {
            setCoinsBalance(localStorage.getItem('coinsBalance'));
        }

        if (localStorage.getItem('gemsBalance')) {
            setGemsBalance(localStorage.getItem('gemsBalance'));
        }
    }, []);

    return (
        <div className="section light-grey" style={{ marginTop: -72 }}>

            <Helmet>
                <title>Contact</title>
                <meta name="description" content="Contact us in case of any inquiries." />
                <meta name="keywords" content="contact, email, delete account" />
                <link rel="canonical" href="https://www.lootsource.app/contact/" />
            </Helmet>

            <div style={{ backgroundImage: 'linear-gradient(120deg, #e85d04, #faa307)', width: '100%', height: 300, marginTop: -80 }} />

            <div className="container w-container">
                <div className="terms-card">
                    <div className="text-box centered">
                        <h1 className="no-margin">Contact</h1>
                        <div className="spacer _16"></div>
                        <p className="paragraph" style={{ textAlign: 'center' }}>Last updated April 2024</p>
                        <div className="spacer _48"></div>
                        <p style={{ textAlign: 'center' }}>Player Support</p>
                        <b>support@lootsource.app</b>
                        <div className="spacer _16"></div>
                        <p style={{ textAlign: 'center' }}>Business Inquiries</p>
                        <b>business@lootsource.app</b>
                        <div className="spacer _48"></div>
                        <p>If you wish to terminate your Lootsource account, you can do so using the interface in the Lootsource app. If you have additional
                            inquiries about this process, encounter issues, or wish manual data removal, feel free to contact <b>Player Support</b> via e-mail
                            address defined above.
                        </p>
                        <p>If you were not rewarded for your effort of completing a game mission or a survey, also feel free to contact our <b>Player Support</b>.
                            Our team will reward you with missing coins.
                        </p>
                        <div className="spacer _48"></div>
                        <p>
                            Average response time of the <b>Player Support</b> team is <b>24 hours</b>, excluding weekends and holidays.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;