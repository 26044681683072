import React from 'react';

import gem from '../assets/icons/Quest_104.webp'
import { decode } from 'html-entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDown, faCircleDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import {isMobile} from 'react-device-detect';

const OfferItem = (props) => {
    const handlePress = () => {
        if (isMobile) {
            window.open('https://play.google.com/store/apps/details?id=com.syntaks.lootsource', '_blank');
            return;
        }

        props.onPress(props.offer);
    };

    return (
        <div
            key={props.offer.id}
            className="cursorPointer"
            style={{
                borderRadius: 12,
                backgroundColor: 'white',
                marginBottom: 8,
                padding: 12,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: 592,
                marginLeft: 4,
                marginRight: 4
            }}
            onClick={handlePress}
        >
            <img
                src={props.offer.icon}
                alt={props.offer.name + ' offer icon'}
                style={{
                    backgroundColor: '#DDDDDD',
                    width: 82,
                    height: 82,
                    objectFit: 'cover',
                    borderRadius: 22,
                    marginRight: 12,
                }}
            />
            <div
                className="infoWrapper"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        marginBottom: 8,
                        textAlign: 'left'
                    }}
                >
                    {decode(props.offer.name)}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 4,
                    }}
                >
                    <img
                        className="coinIcon"
                        src={'https://lootsource.app/index_files/652f04ad8dc4b4f8112e14d2_Loot_06.png'}
                        alt="Coin Icon"
                        style={{ width: 22, height: 22, marginRight: 4 }}
                    />
                    <span style={{ color: '#757575', fontSize: 18 }}>Reward:</span>
                    <span
                        style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: '#FAA307',
                            marginLeft: 4,
                        }}
                    >
                        {` ${Number(props.offer.payout) * 2} Coins`}
                    </span>
                </div>
                {props.offer.payout >= 100 ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'left',
                            alignContent: 'left',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span style={{ color: '#02a2f7', marginRight: 4 }}>+</span>
                            <img
                                className="coinIcon"
                                src={gem}
                                alt="Gem Icon"
                                style={{ width: 22, height: 22, marginRight: 4 }}
                            />
                            <span style={{ color: '#02a2f7', fontSize: 18 }}>Bonus!</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {props.offer.likes ? (
                                <>
                                    <FontAwesomeIcon icon={faThumbsUp} color='#a3a3a3' style={{ width: 22, height: 22 }}></FontAwesomeIcon>
                                    <p style={{ fontSize: 18, margin: 0, marginLeft: 4 }}>{props.offer.likes}</p>
                                </>
                            ) : null}

                            {props.offer.clicks ? (
                                <>
                                    <FontAwesomeIcon icon={faCircleDown} color='#a3a3a3' style={{ width: 18, height: 18, marginLeft: 12 }}></FontAwesomeIcon>
                                    <p style={{ fontSize: 18, margin: 0, marginLeft: 4 }}>{props.offer.clicks}</p>
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p style={{ color: '#757575', textAlign: 'left', marginBottom: 0, fontSize: 18 }}>
                            + {props.offer.rewards.length} more rewards
                        </p>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {props.offer.likes ? (
                                <>
                                    <FontAwesomeIcon icon={faThumbsUp} color='#a3a3a3' style={{ width: 22, height: 22 }}></FontAwesomeIcon>
                                    <p style={{ fontSize: 18, margin: 0, marginLeft: 4 }}>{props.offer.likes}</p>
                                </>
                            ) : null}

                            {props.offer.clicks ? (
                                <>
                                    <FontAwesomeIcon icon={faCircleDown} color='#a3a3a3' style={{ width: 18, height: 18, marginLeft: 12 }}></FontAwesomeIcon>
                                    <p style={{ fontSize: 18, margin: 0, marginLeft: 4 }}>{props.offer.clicks}</p>
                                </>
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OfferItem;
