import { useState } from "react";

const LandingShowcase = (props) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const handleMouseEnter = () => {
        setShowOverlay(true);
    };

    const handleMouseLeave = () => {
        setShowOverlay(false);
    };

    return (
        <div
            className={props.className}
            style={{...props.style, position: 'relative'}}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <img src={props.image} width="60px" className="center-image" alt={props.title + ' Showcase'} />
            <h1 className="showcase-title" style={{fontWeight: 700}}>{props.title}</h1>
            <p className="showcase-text">{props.info}</p>

            <div className={`overlay ${showOverlay ? 'show' : ''}`} style={{padding: 60}}>
                <p className="showcase-text-overlay">{props.overlayText}</p>
            </div>
        </div>
    );
}

export default LandingShowcase;