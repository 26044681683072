import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const MiniOfferMenu = (props) => {

    const getSelectedStyle = (item, type) => {
        if (props.selected === item) {
            if (type === 'background') {
                return { backgroundColor: '#e85d04' };
            } else {
                return { color: 'white', fontWeight: 'bold' };
            }
        }
        return {};
    };

    return (
        <div style={{ display: 'flex', marginBottom: 16}}>
            {props.menuItems.map(item => (
                <div
                    key={item.title}
                    onClick={() => item.onPress(item.title)}
                    style={{
                        ...styles.touchable,
                        ...getSelectedStyle(item.title, 'background'),
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                >
                    {item.icon ? (
                        <img
                            src={item.icon}
                            alt={item.title}
                            style={{ width: 32, height: 32, marginRight: 8 }}
                        />
                    ) : (
                        <FontAwesomeIcon icon={item.faIcon} style={{color: '#4a4a4a', width: 32, height: 32, marginRight: 16, ...getSelectedStyle(item.title, 'text')}} />
                    )}
                    <span
                        style={{
                            fontSize: 18,
                            color: '#4a4a4a',
                            ...getSelectedStyle(item.title, 'text')
                        }}
                    >
                        {item.title}
                    </span>
                </div>
            ))}
        </div>
    );
};

const styles = {
    touchable: {
        padding: '8px 24px',
        borderRadius: 18,
        marginRight: 10,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
    }
};

export default MiniOfferMenu;