import React, { useContext, useEffect, useState } from 'react';

import { getSession, isLoggedIn } from '../lib/session';
import { LoginContext } from './LoginProvider';
import QueryModal from './QueryModal';
import { GlobalContext } from './GlobalProvider';
import axiosClient from '../lib/ax';

const PayPalPayout = (props) => {
    const [payPalEmail, setPayPalEmail] = useState('');
    const [payoutButtonEnabled, setPayoutButtonEnabled] = useState(false);
    const [buttonText, setButtonText] = useState('...');
    const [loading, setLoading] = useState(false);
    const [payoutError, setPayoutError] = useState(null);
    const [payoutSuccess, setPayoutSuccess] = useState(null);

    const { showLoginModal, isLoginModalVisible } = useContext(LoginContext);
    const { setCoinsBalance } = useContext(GlobalContext);

    useEffect(() => {
        if (localStorage.getItem('paypalEmail')) {
            setPayPalEmail(localStorage.getItem('paypalEmail'));
        }
    }, []);

    useEffect(() => {
        updateButtonText();

        if (payPalEmail.includes('@') && payPalEmail.includes('.')) {
            localStorage.setItem('paypalEmail', payPalEmail);
        }
    }, [props, payPalEmail])

    const updateButtonText = () => {
        if (props.coins < 500) {
            setButtonText('Minimum payout amount is $1');
            setPayoutButtonEnabled(false);
            return;
        }

        if (!payPalEmail.length || !payPalEmail.includes('@') || !payPalEmail.includes('.')) {
            setButtonText('Please enter your PayPal e-mail');
            setPayoutButtonEnabled(false);
            return;
        }

        if (loading) {
            setButtonText('...');
            setPayoutButtonEnabled(false);
            return;
        }

        setButtonText(`Payout $${Math.floor(Number(props.coins) / 500).toFixed(2)}`);
        setPayoutButtonEnabled(true);
    }

    const getDisabledSubmitStyle = () => {
        return !payoutButtonEnabled ? { backgroundColor: '#ccc', cursor: 'not-allowed' } : {};
    };

    const onLoginPress = () => {
        showLoginModal();
    }

    const payout = async () => {

        if (!payoutButtonEnabled) {
            return;
        }

        setLoading(true);
        try {
            const response = await axiosClient.get('/payout', {
                params: {
                    session: getSession(),
                    paypalEmail: payPalEmail,
                }
            });

            localStorage.setItem('coinsBalance', 0);
            setCoinsBalance(0);

            setPayoutSuccess("We've received your payout request. It typically takes less than 48 hours to process it and send it to you. Until then, you can track the status of your payout in the 'Purchased' section.")
        } catch (error) {
            setPayoutError(error.message || 'Unexpected error processing your payout. Please contact us.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', backgroundColor: 'white', borderRadius: 36 }}>

            {payoutError ? (
                <QueryModal title="Oops!" message={payoutError} onClose={() => setPayoutError(null)} />
            ) : null}

            {payoutSuccess ? (
                <QueryModal title="Payout request sent!" message={payoutSuccess} onClose={() => setPayoutSuccess(null)} />
            ) : null}

            {(!isLoggedIn() && !isLoginModalVisible) && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 36,
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <p style={{ color: 'white', fontSize: 24, fontWeight: 'bold' }}>Please log in to payout</p>
                    <div className="nav-cta-button-container" onClick={() => onLoginPress()}>
                        <a href="#" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px' }}>
                            Log in
                        </a>
                    </div>
                </div>
            )}
            <div style={{
                backgroundImage: "linear-gradient(220deg, #0461C5, #041538)",
                flex: 1,
                borderTopLeftRadius: 36,
                borderBottomLeftRadius: 36,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img src={'https://www.edigitalagency.com.au/wp-content/uploads/PayPal-icon-white-png-vertical.png'} alt="PayPal Icon" style={{ width: '15%' }} />
            </div>
            <div style={{ flex: 1, padding: 40, paddingTop: 60 }}>
                <p style={{ fontSize: 32, fontWeight: '700', textAlign: 'left' }}>PayPal Payout</p>
                <p style={{ fontSize: 22, color: '#495158', textAlign: 'left' }}>
                    Cashing out your Coins using PayPal is the most popular payout method amongst Lootsource's users.
                </p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <p style={{ textAlign: 'left', fontSize: 18 }}>Coins balance:</p>
                        <p style={{ textAlign: 'left', fontSize: 18 }}>Exchange rate:</p>
                        <p style={{ textAlign: 'left', fontSize: 18 }}>USD value:</p>
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>Lucky Bonus:</p>
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>Total:</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold', color: '#495158' }}>{props.coins} Coins</p>
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold', color: '#495158' }}>500 Coins = $1</p>
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold', color: '#495158' }}>${(Number(props.coins) / 500).toFixed(2)}</p>
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold', color: '#495158' }}>100%</p>
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold', color: '#E85E2E' }}>${(Number(props.coins) / 250).toFixed(2)}</p>
                    </div>
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <input
                        type="text"
                        defaultValue={payPalEmail}
                        placeholder="PayPal e-mail"
                        style={{
                            width: '100%',
                            padding: '16px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            boxSizing: 'border-box',
                            fontSize: '18px',
                        }}
                        onChange={(evt) => setPayPalEmail(evt.target.value)}
                    />
                </div>
                <div>
                    <div
                        type="submit"
                        disabled={!payoutButtonEnabled}
                        style={{
                            width: '100%',
                            padding: '18px',
                            borderRadius: '24px',
                            border: 'none',
                            backgroundColor: '#E85E2E',
                            color: 'white',
                            cursor: 'pointer',
                            marginBottom: '10px',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            ...getDisabledSubmitStyle()
                        }}
                        onClick={() => payout()}
                    >
                        {buttonText}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayPalPayout;