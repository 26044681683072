import React, { useContext, useEffect } from "react";
import { GlobalContext } from "./GlobalProvider";
import { getUser, isLoggedIn } from "../lib/session";

const Controller = ({children}) => {

    const { setCoinsBalance, setGemsBalance } = useContext(GlobalContext);

    useEffect(() => {
        const refreshUserInterval = setInterval(async () => {

            if (!isLoggedIn())
            {
                return;
            }

            const user = await getUser();

            setCoinsBalance(user.coins_balance);
            localStorage.setItem('coinsBalance', user.coins_balance);

            setGemsBalance(user.gems_balance);
            localStorage.setItem('gemsBalance', user.gems_balance);
        }, 20000);

        return () => {
            clearInterval(refreshUserInterval);
        }
    }, []);

    return (
        <>
            {children}
        </>
    )
}

export default Controller;