function timeAgo(currentTimestamp, beforeTimestamp) {

    if (!currentTimestamp) {
        currentTimestamp = Math.floor(Date.now() / 1000);
    }

    const timeDifference = currentTimestamp - beforeTimestamp;
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;

    if (timeDifference < secondsInMinute) {
        return 'a few moments'
    } else if (timeDifference < secondsInHour) {
        const minutesAgo = Math.floor(timeDifference / secondsInMinute);
        return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
    } else if (timeDifference < secondsInDay) {
        const hoursAgo = Math.floor(timeDifference / secondsInHour);
        return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
    } else {
        const daysAgo = Math.floor(timeDifference / secondsInDay);
        return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    }
}

function timeUntil(futureTimestamp) {
    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    let secondsUntil = futureTimestamp - now;

    console.log('sec', futureTimestamp)


    if (secondsUntil <= 0) {
        return "Time is up!";
    }

    const days = Math.floor(secondsUntil / (3600 * 24));
    secondsUntil %= 3600 * 24;
    const hours = Math.floor(secondsUntil / 3600);
    secondsUntil %= 3600;
    const minutes = Math.floor(secondsUntil / 60);

    let result = "In ";
    if (days > 0) {
        result += days + "d";
        if (hours > 0 || minutes > 0) result += ", ";
    }
    if (hours > 0) {
        result += hours + "h";
        if (minutes > 0) result += ", ";
    }
    if (minutes > 0) {
        result += minutes + "m";
    }

    return result;
}

export { timeAgo, timeUntil }