import React from 'react';
import { Helmet } from 'react-helmet';

const TermsOfService = () => (
    <div className="section light-grey" style={{ marginTop: -72 }}>

        <Helmet>
            <title>Terms of Service</title>
            <meta name="description" content="View Lootsource terms of service." />
            <meta name="keywords" content="terms, of, service, tos, terms of service" />
            <link rel="canonical" href="https://www.lootsource.app/terms-of-service/" />
        </Helmet>

        <div style={{ backgroundImage: 'linear-gradient(120deg, #e85d04, #faa307)', width: '100%', height: 300, marginTop: -80 }} />
        <div className="container w-container" style={{ opacity: 1 }}>
            <div className="terms-card">
                <div className="text-box centered">
                    <h1 className="no-margin">Terms of Service</h1>
                    <div className="spacer _16"></div>
                    <p className="paragraph" style={{ textAlign: 'center' }}>Last updated April 2024</p>
                    <div className="spacer _48"></div>
                    <p>These Terms of Service ("Terms") govern your use of the Lootsource mobile application ("App"). By using the App, you agree to abide by these Terms. Please read them carefully before using the App.</p>
                    <p><strong>1. Account Registration</strong></p>
                    <p>1.1. You must create an account to use the Lootsource App. You agree to provide accurate and up-to-date information during the registration process.</p>
                    <p>1.2. You are responsible for maintaining the confidentiality of your account credentials. You should not share your account credentials with others.</p>
                    <p><strong>2. Earning Coins</strong></p>
                    <p>2.1. The Lootsource App allows you to earn coins by completing actions in various games available through the App.</p>
                    <p>2.2. You can also earn coins through daily bonuses, giveaways, and other rewards as provided by Lootsource.</p>
                    <p>2.3. The number of coins you earn may vary based on the actions completed and the terms set by the game developers and Lootsource.</p>
                    <p><strong>3. Use of Coins</strong></p>
                    <p>3.1. Coins earned through the Lootsource App can be used to redeem real-life video games or exchanged for PayPal cashout, as per the options available in the App.</p>
                    <p><strong>4. Payouts</strong></p>
                    <p>4.1. Lootsource reserves the right to cancel any payout, including PayPal cashout or video game redemptions, at any time and for any reason, without prior notice. This includes, but is not limited to, instances of fraud, abuse, or violations of these Terms.</p>
                    <p><strong>5. User Responsibilities</strong></p>
                    <p>5.1. You are responsible for the proper use of the App and the coins you earn. You agree not to engage in any fraudulent or abusive behavior while using the App.</p>
                    <p><strong>6. Changes to the App and Terms</strong></p>
                    <p>6.1. Lootsource reserves the right to modify, suspend, or discontinue the App, its features, or these Terms at any time without prior notice.</p>
                    <p><strong>7. Privacy</strong></p>
                    <p>7.1. Your use of the Lootsource App is also governed by our Privacy Policy, which explains how your data is collected and used. By using the App, you consent to the practices outlined in the Privacy Policy.</p>
                    <p><strong>8. Termination</strong></p>
                    <p>8.1. Lootsource may terminate your access to the App at its sole discretion, with or without cause.</p>
                    <p><strong>9. Contact</strong></p>
                    <p>9.1. For questions or concerns regarding the Lootsource App or these Terms, please contact our customer support at support@lootsource.app</p>
                </div>
            </div>
        </div>
    </div>
);

export default TermsOfService;