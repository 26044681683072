import React, { useContext, useEffect } from 'react';

import surveysPromo from '../landing/assets/surveys web promo.webp';
import { GlobalContext } from '../../components/GlobalProvider';
import { getUser } from '../../lib/session';
import { Helmet } from 'react-helmet';

const Surveys = () => {

    const { setCoinsBalance, setGemsBalance } = useContext(GlobalContext);

    useEffect(() => {
        // refreshUser();

        if (localStorage.getItem('coinsBalance')) {
            setCoinsBalance(localStorage.getItem('coinsBalance'));
        }

        if (localStorage.getItem('gemsBalance')) {
            setGemsBalance(localStorage.getItem('gemsBalance'));
        }
    }, []);

    const refreshUser = async () => {
        const user = await getUser();

        setCoinsBalance(user.coins_balance);
        localStorage.setItem('coinsBalance', user.coins_balance);

        setGemsBalance(user.gems_balance);
        localStorage.setItem('gemsBalance', user.gems_balance);
    }

    return (
        <div className="section light-grey" style={{ marginTop: -72 }}>

            <Helmet>
                <title>Surveys</title>
                <meta name="description" content="Complete surveys by answering simple questions and get paid in return. Surveys feature is currently not available on web, but can be used in our android app." />
                <meta name="keywords" content="questions, surveys, earn" />
                <link rel="canonical" href="https://www.lootsource.app/surveys/" />
            </Helmet>

            <div style={{ maxWidth: 1200, margin: '0 auto', marginTop: 100, overflowY: 'auto' }}>
                <h1 style={{ fontSize: 42, marginBottom: 64, color: '#495158', fontWeight: 700 }}>Get paid for answering questions</h1>
                <div className="showcase-container-wrapper">
                    <div className="cashback-promo-container">
                        <div className="cashback-promo-container-left cashback-promo-padding-top" style={{ flex: 3 }}>
                            <img src={surveysPromo} width="650" alt="Viewing store offers" />
                        </div>
                        <div className="cashback-promo-container-right cashback-promo-padding-right" style={{ flex: 4 }}>
                            <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Coming to web soon!</h1>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Surveys are a great way to earn extra money with little effort. Surveys are currently only available via our Android app, but the Web version is coming soon. Until then, download our android app!
                            </p>
                            <div className="nav-cta-button-container" style={{ marginTop: '40px' }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" className="nav-link cta-button w-nav-link" style={{ maxWidth: '1230px', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '24px', backgroundColor: '#e85d04', color: 'white', fontSize: 16, fontWeight: 'bold' }}>Get for Android</a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}



export default Surveys;