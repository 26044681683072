import Modal from 'react-modal';
import axiosClient from '../lib/ax';
import { useContext, useState } from 'react';
import { LoginContext, hideLoginModal } from './LoginProvider';
import { GoogleAuthProvider, getAuth, signInWithPopup } from '@firebase/auth';
import cryptojs from 'crypto-js';

const LoginModal = (props) => {

    const { isLoginModalVisible, hideLoginModal } = useContext(LoginContext);

    const [loginButtonEnabled, setLoginButtonEnabled] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const loginClassic = async () => {

        if (email == '') {
            setError('E-mail is required');
            return;
        }

        if (password == '') {
            setError('Password is required');
            return;
        }

        setLoginButtonEnabled(false);
        try {
            const response = await axiosClient.get('/authenticate', {
                params: {
                    email,
                    password: cryptojs.SHA256(password).toString(cryptojs.enc.Hex),
                }
            });

            const session = response.data.session;
            const userId = response.data.userId;
            const coins = response.data.coins;
            const gems = response.data.gems;
            localStorage.setItem('session', session);
            localStorage.setItem('userId', userId);
            localStorage.setItem('coinsBalance', coins);
            localStorage.setItem('gemsBalance', gems);
            localStorage.setItem('paypalEmail', '');

            hideLoginModal();

            window.location.reload();
        } catch (error) {

            switch (error.message) {
                case 'authentication_failed':
                    setError('Oops! Your credentials seem to be invalid.');
                    break;

                default:
                    setError('Unexpected login error occurred.');
                    break;
            }
            console.error(error);
        } finally {
            setLoginButtonEnabled(true);
        }
    }

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "700px",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "32px",
            padding: "0px",
            maxHeight: "70%",
        },
    };

    const getDisabledStyle = () => {
        if (!loginButtonEnabled) {
            return {
                backgroundColor: '#dbdbdb',
            }
        }

        return {};
    }

    const loginGoogle = async () => {
        try {
            const auth = getAuth();

            const provider = new GoogleAuthProvider();

            const googleSignInResult = await signInWithPopup(auth, provider);

            setLoginButtonEnabled(false);

            const user = googleSignInResult.user;
            
            const response = await axiosClient.get('/googleauthenticateweb', {
                params: {
                    user: JSON.stringify(user),
                    googleToken: user.accessToken,
                }
            });

            const session = response.data.session;
            const userId = response.data.userId;
            const coins = response.data.coins;
            const gems = response.data.gems;
            localStorage.setItem('session', session);
            localStorage.setItem('userId', userId);
            localStorage.setItem('coinsBalance', coins);
            localStorage.setItem('gemsBalance', gems);
            localStorage.setItem('paypalEmail', '');

            hideLoginModal();

            window.location.reload();
        } catch (error) {
            console.error(error);
        } finally {
            setLoginButtonEnabled(true);
        }
    }

    return (
        <Modal
            isOpen={isLoginModalVisible}
            onRequestClose={() => hideLoginModal()}
            contentLabel={'Login to Lootsource'}
            style={customStyles}
        >
            <div style={{ padding: 40, backgroundColor: '#F5F6F7', textAlign: 'center' }}>
                <h1 style={{ marginBottom: '30px', color: '#333', fontWeight: 700 }}>Login</h1>
                {error && error.length ? (
                    <p style={{ marginBottom: '30px', color: 'maroon', fontSize: 18 }}>{error}</p>
                ) : (
                    <p style={{ marginBottom: '30px', color: '#333', fontSize: 18 }}>Logging in will unlock more amazing features!</p>
                )}
                <form>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="text"
                            placeholder="E-mail"
                            style={{
                                width: '100%',
                                padding: '16px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                boxSizing: 'border-box',
                                fontSize: '18px',
                            }}
                            onChange={(evt) => setEmail(evt.target.value)}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="password"
                            placeholder="Password"
                            style={{
                                width: '100%',
                                padding: '16px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                boxSizing: 'border-box',
                                fontSize: '18px',
                            }}
                            onChange={(evt) => setPassword(evt.target.value)}
                        />
                    </div>
                    <div>
                        <div
                            type="submit"
                            disabled={!loginButtonEnabled}
                            style={{
                                width: '100%',
                                padding: '18px',
                                borderRadius: '24px',
                                border: 'none',
                                backgroundColor: '#E85E2E',
                                color: 'white',
                                cursor: 'pointer',
                                marginBottom: '10px',
                                fontWeight: 'bold',
                                fontSize: '18px',
                                ...getDisabledStyle()
                            }}
                            onClick={() => loginClassic()}
                        >
                            Log In
                        </div>
                    </div>
                    <div>
                        <div
                            type="button"
                            disabled={!loginButtonEnabled}
                            style={{
                                width: '100%',
                                padding: '18px',
                                borderRadius: '24px',
                                border: 'none',
                                backgroundColor: '#4285F4',
                                fontSize: '18px',
                                color: 'white',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                ...getDisabledStyle()
                            }}
                            onClick={() => loginGoogle()}
                        >
                            Log In with Google
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default LoginModal;