import React, { useEffect, useState } from "react";
import axiosClient from "../lib/ax";
import Modal from 'react-modal';
import { getSession, getUserId, isLoggedIn } from "../lib/session";
import { decode } from "html-entities";

import gem from '../assets/icons/Quest_104.webp';
import coin from '../assets/icons/Loot_06.webp';
import { PacmanLoader } from "react-spinners";

const SelectedGame = (props) => {

    const [loading, setLoading] = useState(true);
    const [game, setGame] = useState(null);
    const [missions, setMissions] = useState([]);

    useEffect(() => {
        fetchGame();
    }, []);

    const fetchGame = async () => {
        try {
            const response = await axiosClient.get('/getofferbyidweb', {
                params: {
                    session: getSession(),
                    offerId: props.selectedOfferId,
                    country: localStorage.getItem('countryCode') || 'US',
                }
            });

            setGame(response.data);

            setMissions(JSON.parse(response.data.missions));

            console.log(response.data);

        } catch (error) {

            switch (error.message) {
                case 'offer_not_exists':
                    props.onError('This game is no longer available. This can happen if you changed your location, or the game mission simply expired.');
                    props.onClose();
                    break;

                default:
            }

            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const startMission = () => {

        try {
            axiosClient.get('/startoffer', {
                params: {
                    userIp: localStorage.getItem('ip') || '',
                    userCountry: localStorage.getItem('countryCode') || 'US',
                    session: getSession(),
                    offerId: props.selectedOfferId,
                }
            })
        } catch (error) {
            console.error(error);
        }

        window.open(game.click_url + getUserId(), "_blank", "noreferrer");
    }

    const revisitMission = () => {
        window.open(game.click_url + getUserId(), "_blank", "noreferrer");
    }

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "600px",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "32px",
            padding: "0px",
            maxHeight: "70%"
        },
    };

    const getBgStyle = () => {
        if (loading) {
            return {
                content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    width: "600px",
                    transform: "translate(-50%, -50%)",
                    padding: "0px",
                    maxHeight: "70%",
                    backgroundColor: '#00000000',
                    border: '0px solid #00000000'
                }
            }
        }

        return {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                width: "600px",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                borderRadius: "32px",
                padding: "0px",
                maxHeight: "70%"
            },
        }
    }

    return (
        <Modal
            isOpen={true}
            onRequestClose={() => props.onClose()}
            contentLabel={'Selected Game Offer'}
            style={getBgStyle()}
        >
            {loading ? (
                <div style={{ display: 'flex', padding: 32, justifyContent: 'center', height: 200 }}>
                    <PacmanLoader color="#D76429"></PacmanLoader>
                </div>
            ) : (
                <>
                    {game ? (
                        <>
                            <div style={{ width: '100%', height: 100, borderTopLeftRadius: 32, borderTopRightRadius: 32, backgroundImage: `url(${game.icon})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', filter: 'blur(8px)' }} />
                            <div style={{ padding: 20, backgroundColor: '#F5F6F7' }}>
                                <p style={{ fontSize: 24, fontWeight: 'bold', color: '#495158' }}>{decode(game.name)}</p>

                                {game.state === 'inprogress' ? (
                                    <div style={{ backgroundColor: 'white', borderRadius: 24, padding: 16, marginBottom: 8, display: 'flex', flexDirection: 'row' }} className="cursorPointer" onClick={() => startMission()}>
                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#E85E2E', flex: 3 }}>
                                            In progress
                                        </p>
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 9 }}>
                                            <p style={{ fontSize: 18, color: '#757575' }}>
                                                We are waiting for you to finish one or more missions. We will reward you once you do.
                                            </p>
                                        </div>
                                    </div>
                                ) : null}

                                {game.state === 'pending' ? (
                                    <div style={{ backgroundColor: 'white', borderRadius: 24, padding: 16, marginBottom: 8, display: 'flex', flexDirection: 'row' }} className="cursorPointer" onClick={() => startMission()}>
                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#E85E2E', flex: 3 }}>
                                            Reviewing
                                        </p>
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 9 }}>
                                            <p style={{ fontSize: 18, color: '#757575' }}>
                                                Great! You finished one or more missions in this game. We are just checking to see if everything is legit. You will receive your reward soon.
                                            </p>
                                        </div>
                                    </div>
                                ) : null}

                                {game.state === 'approved' ? (
                                    <div style={{ backgroundColor: 'white', borderRadius: 24, padding: 16, marginBottom: 8, display: 'flex', flexDirection: 'row' }} className="cursorPointer" onClick={() => startMission()}>
                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#E85E2E', flex: 3 }}>
                                            Completed
                                        </p>
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 9 }}>
                                            <p style={{ fontSize: 18, color: '#757575' }}>
                                                You did a great job! We gifted your account with rewards.
                                            </p>
                                            <div style={{ backgroundColor: 'white', padding: 6, borderRadius: 36, height: 36, display: 'flex', flexDirection: 'row' }}>
                                                <img src={coin} style={{ width: 26, height: 26 }} alt="Coin Icon"></img>
                                                <p style={{ fontSize: 18, fontWeight: 'bold', color: '#E85E2E', marginLeft: 4 }}>{game.coins_earned}</p>
                                                {game.gems_earned ? (
                                                    <>
                                                        <img src={gem} style={{ width: 26, height: 26, marginLeft: 12 }} alt="Gem Icon"></img>
                                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#02a2f7', marginLeft: 4 }}>{game.gems_earned}</p>
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}

                                {game.state === 'canceled' ? (
                                    <div style={{ backgroundColor: 'white', borderRadius: 24, padding: 16, marginBottom: 8, display: 'flex', flexDirection: 'row' }} className="cursorPointer" onClick={() => startMission()}>
                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#E85E2E', flex: 3 }}>
                                            Canceled
                                        </p>
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 9 }}>
                                            <p style={{ fontSize: 18, color: '#757575' }}>
                                                Upon reviewing, we've decided to cancel your reward for this game. Contact us for more details.
                                            </p>
                                        </div>
                                    </div>
                                ) : null}

                                <div style={{ backgroundColor: 'white', borderRadius: 24, padding: 16, marginBottom: 8, display: 'flex', flexDirection: 'row' }} className="cursorPointer" onClick={() => startMission()}>
                                    <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black', flex: 3 }}>
                                        Description
                                    </p>
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 9 }}>
                                        <p style={{ fontSize: 18, color: '#757575' }}>
                                            {decode(game.tasks)}
                                        </p>
                                    </div>
                                </div>

                                {missions.map((mission, index) => (
                                    <div style={{ backgroundColor: 'white', borderRadius: 24, padding: 16, marginBottom: 8, display: 'flex', flexDirection: 'row' }} key={index} className="cursorPointer" onClick={() => startMission()}>
                                        <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black', flex: 3 }}>
                                            Mission {index + 1}
                                        </p>
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 9 }}>
                                            <p style={{ fontSize: 18, color: '#757575' }}>
                                                {decode(mission.mission)}
                                            </p>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <p style={{ fontSize: 18, color: '#757575', margin: 0 }}>
                                                    Rewards:
                                                </p>
                                                <img
                                                    className="coinIcon"
                                                    src={coin}
                                                    alt="Coin Icon"
                                                    style={{ width: 22, height: 22, marginRight: 2, marginLeft: 4 }}
                                                />
                                                <p style={{ fontWeight: 'bold', margin: 0, fontSize: 18, color: '#F4A42B', verticalAlign: 'center' }}>{mission.reward}</p>
                                                {Number(mission.reward) >= 100 ? (
                                                    <span style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'center', alignItems: 'center', marginLeft: 12 }}>
                                                        <img
                                                            className="coinIcon"
                                                            src={gem}
                                                            alt="Coin Icon"
                                                            style={{ width: 22, height: 22, marginRight: 2 }}
                                                        />
                                                        <p style={{ fontWeight: 'bold', margin: 0, fontSize: 18, color: '#02a2f7', verticalAlign: 'center' }}>{Math.floor(Number(mission.reward) / 100)}</p>
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {isLoggedIn() ? (
                                    <>
                                        {game.state === 'notstarted' ? (
                                            <div style={{ backgroundColor: '#E85E2E', padding: 18, borderRadius: 22 }} className="cursorPointer" onClick={() => startMission()}>
                                                <p style={{ fontSize: 18, color: 'white', fontWeight: 'bold', margin: 0, textAlign: 'center' }}>Start Missions</p>
                                            </div>
                                        ) : (
                                            <div style={{ backgroundColor: '#E85E2E', padding: 18, borderRadius: 22 }} className="cursorPointer" onClick={() => revisitMission()}>
                                                <p style={{ fontSize: 18, color: 'white', fontWeight: 'bold', margin: 0, textAlign: 'center' }}>Visit Again</p>
                                            </div>
                                        )}

                                    </>
                                ) : (
                                    <div style={{ backgroundColor: '#a8a8a8', padding: 18, borderRadius: 22 }} className="cursorPointer">
                                        <p style={{ fontSize: 18, color: 'white', fontWeight: 'bold', margin: 0, textAlign: 'center' }}>Log in to start</p>
                                    </div>
                                )}

                            </div>
                        </>
                    ) : null}
                </>
            )}
        </Modal>
    )
}

export default SelectedGame;