import React, { useEffect, useState, useRef, useContext } from "react";
import axiosClient from "../../lib/ax"; // Assuming axiosClient is correctly configured
import OfferItem from "../../components/OfferItem";
import { PacmanLoader } from "react-spinners";
import MiniOfferMenu from "../../components/MiniOfferMenu";
import Modal from 'react-modal';

import gem from '../../assets/icons/Quest_104.webp';
import coin from '../../assets/icons/Loot_06.webp';
import cake from '../../assets/icons/Res_146.webp';
import goldBars from '../../assets/icons/Quest_09.webp';
import { getSession, getUser, getUserId, isLoggedIn } from "../../lib/session";
import { decode } from "html-entities";
import SelectedGame from "../../components/SelectedGame";
import { GlobalContext } from "../../components/GlobalProvider";
import { Helmet } from "react-helmet";
import { BrowserView, MobileView } from "react-device-detect";
import QueryModal from "../../components/QueryModal";
import { LoginContext } from "../../components/LoginProvider";

const Games = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [menuSelected, setMenuSelected] = useState('Trending');
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const [selectedGameError, setSelectedGameError] = useState(null);

    const initialLoaded = useRef(false);
    const containerRef = useRef(null);

    const { setCoinsBalance, setGemsBalance } = useContext(GlobalContext);
    const { isLoginModalVisible } = useContext(LoginContext);

    useEffect(() => {
        getGames();
        refreshUser();
    }, []);

    useEffect(() => {
        if (page > 0) {
            clearGames();
        } else {
            if (!initialLoaded.current) {
                return;
            }
            clearGames();
            loadMoreGames();
        }
    }, [menuSelected]);

    useEffect(() => {
        if (!initialLoaded.current) {
            return;
        }
        loadMoreGames();
    }, [page]);

    const refreshUser = async () => {
        const user = await getUser();

        setCoinsBalance(user.coins_balance);
        localStorage.setItem('coinsBalance', user.coins_balance);

        setGemsBalance(user.gems_balance);
        localStorage.setItem('gemsBalance', user.gems_balance);
    }

    const getGames = async () => {
        try {
            setLoading(true);
            const response = await axiosClient.get('/getoffersweb', {
                params: {
                    session: getSession(),
                    sort: menuSelected.replaceAll(' ', '').toLowerCase(),
                    country: localStorage.getItem('countryCode') || 'US',
                    page: 0,
                }
            });

            setGames(response.data);
            setHasMore(response.data.length > 0);
        } catch (error) {
            console.error(error);
        } finally {
            setInitialLoading(false);
            setLoading(false);
            initialLoaded.current = true;
        }
    }

    const loadMoreGames = async () => {
        try {
            setLoading(true);
            const response = await axiosClient.get('/getoffersweb', {
                params: {
                    session: getSession(),
                    sort: menuSelected.replaceAll(' ', '').toLowerCase(),
                    country: localStorage.getItem('countryCode') || 'US',
                    page: page,
                }
            });

            if (response.data.length > 0) {
                setGames((prevGames) => [...prevGames, ...response.data]);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const selectMenuItem = item => {
        setMenuSelected(item);
    };

    const clearGames = () => {
        setGames([]);
        setPage(0);
    }

    return (
        <div className="section light-grey" style={{ marginTop: -72 }}>

            <Helmet>
                <title>Games</title>
                <meta name="description" content="Test video game missions and earn coins in return. Hundereds of games to pick from. Filter games by trending, difficulty and reward amount." />
                <meta name="keywords" content="games, missions, earn, coins, play" />
                <link rel="canonical" href="https://www.lootsource.app/games/" />
            </Helmet>

            {(selectedOfferId && !isLoginModalVisible) ? (
                <SelectedGame selectedOfferId={selectedOfferId} onClose={() => setSelectedOfferId(null)} onError={(message) => setSelectedGameError(message)}/>
            ) : null}

            {selectedGameError ? (
                <QueryModal title={'Oops!'} message={selectedGameError} onClose={() => setSelectedGameError(null)} />
            ) : null}

            <div style={{ maxWidth: 1200, margin: '0 auto', marginTop: 100, overflowY: 'auto' }} ref={containerRef}>

                {initialLoading ? (
                    <div style={{ display: 'flex', padding: 32, justifyContent: 'center', height: 900 }}>
                        <PacmanLoader color="#D76429"></PacmanLoader>
                    </div>
                ) : (
                    <>
                        <h1 style={{ fontSize: 42, marginBottom: 64, color: '#495158', fontWeight: 700 }}>Complete missions, win cash</h1>
                        <BrowserView>
                            <MiniOfferMenu
                                selected={menuSelected}
                                menuItems={[
                                    {
                                        title: 'Bonus',
                                        icon: gem,
                                        onPress: item => selectMenuItem(item),
                                    },
                                    {
                                        title: 'Trending',
                                        icon: coin,
                                        onPress: item => selectMenuItem(item),
                                    },
                                    {
                                        title: 'Easy',
                                        icon: cake,
                                        onPress: item => selectMenuItem(item),
                                    },
                                    {
                                        title: 'Big Reward',
                                        icon: goldBars,
                                        onPress: item => selectMenuItem(item),
                                    },
                                ]}
                            />
                        </BrowserView>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            {games.map((game, index) => (
                                <OfferItem offer={game} key={index} onPress={(game) => setSelectedOfferId(game.offer_id)} />
                            ))}
                        </div>
                        <div style={{ display: 'flex', padding: 32, justifyContent: 'center' }}>
                            {loading ? (
                                <PacmanLoader color="#D76429" style={{ marginBottom: 900 }}></PacmanLoader>
                            ) : (
                                <>
                                    {hasMore ? (
                                        <p style={{ textDecoration: 'underline', fontSize: 18, fontWeight: 'bold' }} className="cursorPointer" onClick={() => setPage(page + 1)}>View more</p>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div >
    )
}

export default Games;
