import React, { useContext, useEffect } from 'react';

import cashback1 from './assets/cashback02-nobg.webp';
import cashback2 from './assets/cashback03-nobg.webp';
import cashback3 from './assets/cashback04-nobg.webp';
import cashback4 from './assets/cashback05-nobg.webp';
import playStoreBadge from '../landing/assets/653182c9697ae17fa4f60fb5_download_playstore.webp';
import { GlobalContext } from '../../components/GlobalProvider';
import { getUser } from '../../lib/session';
import { Helmet } from 'react-helmet';
import { BrowserView, MobileView } from 'react-device-detect';

const Cashback = () => {

    const { setCoinsBalance, setGemsBalance } = useContext(GlobalContext);

    useEffect(() => {
        if (localStorage.getItem('coinsBalance')) {
            setCoinsBalance(localStorage.getItem('coinsBalance'));
        }

        if (localStorage.getItem('gemsBalance')) {
            setGemsBalance(localStorage.getItem('gemsBalance'));
        }
    }, []);

    const refreshUser = async () => {
        const user = await getUser();

        setCoinsBalance(user.coins_balance);
        localStorage.setItem('coinsBalance', user.coins_balance);

        setGemsBalance(user.gems_balance);
        localStorage.setItem('gemsBalance', user.gems_balance);
    }

    return (
        <div className="section light-grey" style={{ marginTop: -72 }}>

            <Helmet>
                <title>Cashback</title>
                <meta name="description" content="Receive cashback for shopping in your favorite stores. Take a picture of your receipt and get a cashback. Currently only available via our android app." />
                <meta name="keywords" content="swagbucks, cashback, stores, wallmart, target, margic receipts" />
                <link rel="canonical" href="https://www.lootsource.app/cashback/" />
            </Helmet>

            <div style={{ maxWidth: 1200, margin: '0 auto', marginTop: 100, overflowY: 'auto' }}>
                <h1 style={{ fontSize: 42, marginBottom: 64, color: '#495158', fontWeight: 700 }}>Get your shopping money back</h1>

                <div className="showcase-container-wrapper">
                    <div className="cashback-promo-container">
                        <div className="cashback-promo-container-left cashback-promo-padding-top" style={{ flex: 3 }}>
                            <img src={cashback1} width="650" alt="Viewing store offers" />
                        </div>
                        <div className="cashback-promo-container-right cashback-promo-padding-right" style={{ flex: 4 }}>
                            <h1 className="cashback-heading" style={{ textAlign: 'left' }}>View store offers</h1>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>When you enter the Lootsource app and login, tap the 'Get shopping money back' to navigate to the cashback store page.</p>
                            <p style={{ textAlign: 'left', color: '#495158', fontSize: 22, marginTop: 32, fontWeight: 'bold', color: 'black' }}>Filtering items</p>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Offers of some items are valid only at certain stores. You can filter item offers by store and category.</p>
                        </div>
                    </div>
                </div>

                <div className="showcase-container-wrapper">
                    <div className="cashback-promo-container">
                        <div className="cashback-promo-container-right cashback-promo-padding-left" style={{ flex: 4 }}>
                            <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Add items to receipt</h1>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Once you found an appropriate offer, you can simply add it to your receipt. You can add as many items as you wish, but all receipt items need to be bought in the same store.</p>
                            <p style={{ textAlign: 'left', color: '#495158', fontSize: 22, marginTop: 32, fontWeight: 'bold', color: 'black' }}>Managing items</p>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>You can define the amount of items on your receipt, or remove them at any time.</p>
                        </div>

                        <div className="cashback-promo-container-left cashback-promo-padding-top" style={{ flex: 3 }}>
                            <img src={cashback2} width="650" alt="Adding items to receipt" />
                        </div>
                    </div>
                </div>

                <div className="showcase-container-wrapper">
                    <div className="cashback-promo-container">
                        <div className="cashback-promo-container-left cashback-promo-padding-top" style={{ flex: 3 }}>
                            <img src={cashback3} width="650" alt="Taking photo of receipt" />
                        </div>
                        <div className="cashback-promo-container-right cashback-promo-padding-right" style={{ flex: 4 }}>
                            <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Picture the receipt</h1>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>When you're done purchasing items previously placed on your reciept, it's time to get some cashbacks! Take a clear picture of your store receipt. Make sure all items are visible on the receipt.</p>
                            <p style={{ textAlign: 'left', color: '#495158', fontSize: 22, marginTop: 32, fontWeight: 'bold', color: 'black' }}>Take clear photos</p>
                            <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Make sure the whole receipt is visible. You can take multiple pictures if the receipt is long. All receipt items must be visible.</p>
                        </div>
                    </div>
                </div>

                <div style={{ maxWidth: 1200, backgroundColor: 'white', padding: 60, paddingTop: 100, borderRadius: 36, paddingBottom: 0 }}>
                    <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Get your Cashback!</h1>
                    <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Cashbacks are often rewarded <b>automatically</b> by the AI receipt recognition seconds after you submit receipt images.</p>
                    <p style={{ textAlign: 'left', color: '#495158', fontSize: 22, marginTop: 32, fontWeight: 'bold', color: 'black' }}>Manual reviewal</p>
                    <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Receipts are sometimes going to be sent for manual reviewal. This process typically takes less than 48 hours, excluding weekends.</p>
                    <div className="cashback-container-left" style={{ paddingTop: 80 }}>
                        <img src={cashback4} width="700" style={{ paddingTop: 0 }} alt="Receiving your cashback" />
                    </div>
                </div>

                <BrowserView className="download-container" style={{ marginTop: 80 }}>
                    <div className="download-container-left">
                        <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" rel="noopener noreferrer">
                            <img src={playStoreBadge} alt="Google Play store download badge" />
                        </a>
                    </div>
                    <div className="download-container-right">
                        <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Get The App Now</h1>
                        <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Cashbacks are currently only available via our official Android app. If you wish to earn cashback from buying your favorite store items, download Lootsource on your android device!</p>
                    </div>
                </BrowserView>

                <MobileView className="download-container" style={{ marginTop: 80 }}>
                    <div className="download-container-top">
                        <h1 className="cashback-heading" style={{ textAlign: 'left' }}>Get The App Now</h1>
                        <p className="cashback-info-text" style={{ textAlign: 'left', color: '#495158' }}>Cashbacks are currently only available via our official Android app. If you wish to earn cashback from buying your favorite store items, download Lootsource on your android device!</p>
                    </div>
                    <div className="download-container-bottom">
                        <a href="https://play.google.com/store/apps/details?id=com.syntaks.lootsource" target="_blank" rel="noopener noreferrer">
                            <img src={playStoreBadge} alt="Google Play store download badge" />
                        </a>
                    </div>
                </MobileView>
            </div>
        </div >
    )
}

export default Cashback;