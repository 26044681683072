import axios from 'axios';
import cryptojs from 'crypto-js';
const ak = 'PaGDvKKSWRlDj960SdiCw2KpA0BVV4hIxQpIszjxljqCvN7ANWB2TzcNmiUYReyS';

const config = {
  baseURL: 'https://jqt1zwflv8.execute-api.eu-central-1.amazonaws.com',
  // baseURL: 'http://localhost:8090',
  timeout: 5 * 1000,
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  (config) => {
    if (config.method === 'get' || config.method === 'post') {
      
      const sortedParams = Object.keys(config.params).sort().reduce((acc, key) => {
        acc[key] = config.params[key];
        return acc;
      }, {});

      const concatenatedValues = Object.values(sortedParams).join("") + ak;
      const secretToken = cryptojs.SHA256(concatenatedValues).toString(cryptojs.enc.Hex);
      config.params.secret = secretToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  async res => {
    if (res.data.status > 299) {
      return Promise.reject({
        status: res.data.status,
        message: res.data.message,
      });
    }
    return res.data;
  },
  error => {
    return Promise.reject({
      status: 100,
      message: 'axios_error',
      more: error,
    });
  },
);

export default axiosClient;
