import axiosClient from "./ax";
import config from "./config";

const getSession = () => {
    let session = '';

    session = localStorage.getItem('session');

    if (!session) {
        session = config.demoSession;
    }

    return session;
}

const resetSession = () => {
    const newSession = config.demoSession;

    localStorage.setItem('session', newSession);
}

const isLoggedIn = () => {
    const session = localStorage.getItem('session');

    if (!session || !session.length) {
        return false;
    }

    if (session === config.demoSession) {
        return false;
    }

    return true;
}

const getUserId = () => {
    const userId = localStorage.getItem('userId');
    return userId;
}

const getUser = async () => {
    const response = await axiosClient.get('/getuserweb', {
        params: {
            session: getSession(),
        }
    });
    return response.data;
}

export {
    getSession,
    resetSession,
    isLoggedIn,
    getUserId,
    getUser,
}