import Modal from 'react-modal';

const QueryModal = (props) => {
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "700px",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "32px",
            padding: "0px",
            maxHeight: "70%",
        },
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={() => props.onClose()}
            contentLabel={'Notice'}
            style={customStyles}
        >
            <div style={{ padding: 40, backgroundColor: '#F5F6F7', textAlign: 'center' }}>
                <h2 style={{ fontWeight: 700 }}>{props.title}</h2>
                <p style={{ fontSize: 18 }}>{props.message}</p>

                <div
                    type="button"
                    style={{
                        width: '100%',
                        padding: '18px',
                        borderRadius: '24px',
                        border: 'none',
                        backgroundColor: '#E85E2E',
                        fontSize: '18px',
                        color: 'white',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                    }}
                    onClick={() => props.onClose()}
                >
                    Close
                </div>
            </div>
        </Modal>
    )
}

export default QueryModal;